/* eslint-disable react/jsx-no-useless-fragment */
/** @format */

import { ELD_SYSTEMS } from "@roadflex/constants";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  Toast,
} from "@roadflex/react-components-www";
import { EldSystem, GeoTabAuthValues } from "@roadflex/types";
import { FormikProps } from "formik";
import getConfig from "next/config";
import { Dialog } from "primereact/dialog";
import { useEffect, useState } from "react";
import { CheckboxInput, TextInput } from "../../inputs";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_APP_URL } = publicRuntimeConfig;

interface AddELDSystemModalProps extends FormikProps<GeoTabAuthValues> {
  openAddELDSystemModal: boolean;
  setOpenAddELDSystemModal: (value: boolean) => void;
  eldSystems: EldSystem[];
  selectedEldSystem: EldSystem;
  setSelectedEldSystem: (value: EldSystem) => void;
}

export default function AddELDSystemModal({
  openAddELDSystemModal,
  setOpenAddELDSystemModal,
  handleChange,
  isSubmitting,
  values,
  handleSubmit,
  setFieldValue,
  eldSystems,
  selectedEldSystem,
  setSelectedEldSystem,
  resetForm,
}: AddELDSystemModalProps) {
  const [agreement, setAgreement] = useState(false);

  const authenticateButton = (
    <Button
      type={ButtonType.Button}
      variant={ButtonVariant.Primary}
      size={ButtonSize.AppSize}
      loading={isSubmitting}
      onClick={(e) => handleSubmit()}
      disabled={
        isSubmitting ||
        (selectedEldSystem?.code === ELD_SYSTEMS.FLEETUP && !agreement)
      }
    >
      Authenticate
    </Button>
  );

  const renderFooter = () => {
    return (
      <div>
        <Button
          type={ButtonType.Submit}
          variant={ButtonVariant.Secondary}
          size={ButtonSize.AppSize}
          className="ml-2"
          onClick={() => {
            resetForm();
            setOpenAddELDSystemModal(false);
          }}
        >
          Close
        </Button>
      </div>
    );
  };

  useEffect(() => {
    setFieldValue("orgName", selectedEldSystem.code);
    const queryParams = new URLSearchParams(window.location.search);
    const bouncieCode = queryParams.get("code");
    const bouncieState = queryParams.get("state");
    if (bouncieState === "BOUNCIE") {
      setFieldValue("token", bouncieCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBouncieConnect = () => {
    if (values.token) {
      handleSubmit();
    } else {
      try {
        const params = new URLSearchParams({
          client_id: "roadflex",
          response_type: "code",
          redirect_uri: `${NEXT_PUBLIC_APP_URL}/eld-systems`,
          state: "BOUNCIE",
        });

        window.location.href = `https://auth.bouncie.com/dialog/authorize?${params}`;
      } catch (err) {
        const errorMessage =
          err instanceof Error
            ? err.message
            : "Failed to connect Bouncie account";
        Toast({ type: "error", message: errorMessage });
      }
    }
  };

  return (
    <Dialog
      header={
        <div className="text-base">{`Connect ${selectedEldSystem.name}`}</div>
      }
      visible={openAddELDSystemModal}
      className="w-[95%] sm:w-[70vw]"
      footer={renderFooter()}
      onHide={() => {
        resetForm();
        setOpenAddELDSystemModal(false);
      }}
    >
      <div className="sm:rounded-lg">
        {selectedEldSystem?.code === ELD_SYSTEMS.GEOTAB ? (
          <div className=" max-w-7xl">
            <div className="flex flex-col items-start gap-4 mt-4 flex-nowrap xl:flex-row">
              <div className="flex flex-col w-full text-gray-700">
                <div className="max-w-7xl xl:flex xl:items-stretch">
                  <div className="w-full xl:w-2/3">
                    <div>
                      Enter the credentials for the service account and the
                      database name
                    </div>
                    <div className="flex mt-3">
                      <TextInput
                        type="text"
                        placeholder="Enter Username"
                        value={values.userName}
                        onChange={handleChange}
                        name="userName"
                        className="w-full"
                      ></TextInput>
                    </div>
                    <div className="flex mt-3">
                      <TextInput
                        type="password"
                        placeholder="Enter Password"
                        value={values.password}
                        onChange={handleChange}
                        name="password"
                      ></TextInput>
                    </div>
                    <div className="flex mt-3">
                      <TextInput
                        type="text"
                        placeholder="Enter Database Name"
                        value={values.database}
                        onChange={handleChange}
                        name="database"
                      ></TextInput>
                    </div>
                    <div className="flex flex-row-reverse mt-3">
                      {authenticateButton}
                    </div>
                  </div>

                  <div className="w-[1px] h-[inherit] border-r mx-5 border-brown-500"></div>
                  <hr className="w-full col-span-5 my-6 border-1 border-brown-500 xl:hidden" />

                  <div className="w-full xl:w-1/3 sm:min-w-[350px] text-gray-700">
                    <div className="flex flex-col col-span-2 mt-6 xl:mt-0 xl:col-span-1">
                      <div className="mb-2 text-xl font-bold text-black">
                        How it works
                      </div>
                      <ul className="text-sm font-medium list-decimal list-inside">
                        <li className="py-2">
                          Add a service account to a database via the MyGeotab
                          account/platform
                        </li>
                        <li className="py-2">
                          The steps are as follows: Administration &gt; Users
                          &gt; Add &gt; Fill in all the service account details
                          &gt; Save
                        </li>
                        <li className="py-2">
                          Add the service account and connect your GeoTab
                          database with RoadFlex
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {selectedEldSystem?.code === ELD_SYSTEMS.SAMSARA ? (
              <div className=" max-w-7xl">
                <div className="flex flex-col items-start gap-4 mt-4 flex-nowrap xl:flex-row">
                  <div className="flex flex-col w-full text-gray-700">
                    <div className="max-w-7xl xl:flex xl:items-stretch">
                      <div className="w-full xl:w-2/3">
                        <div>Enter the API token from your Samsara account</div>
                        <div className="flex mt-3">
                          <TextInput
                            type="text"
                            placeholder="Enter token"
                            value={values.token}
                            onChange={handleChange}
                            name="token"
                          ></TextInput>
                        </div>
                        <div className="flex flex-row-reverse mt-3">
                          {authenticateButton}
                        </div>
                      </div>

                      <div className="w-[1px] h-[inherit] border-r mx-5 border-brown-500"></div>
                      <hr className="w-full col-span-5 my-6 border-1 border-brown-500 xl:hidden" />

                      <div className="w-full xl:w-1/3 sm:min-w-[350px] text-gray-700">
                        <div className="flex flex-col col-span-2 mt-6 xl:mt-0 xl:col-span-1">
                          <div className="mb-2 text-xl font-bold text-black">
                            How it works
                          </div>
                          <ul className="text-sm font-medium list-decimal list-inside">
                            <li className="py-2">
                              Create an API token via the Samsara
                              account/platform
                            </li>
                            <li className="py-2">
                              Connect to the Samsara account using the API token
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {selectedEldSystem?.code === ELD_SYSTEMS.AZUGA ? (
                  <div className=" max-w-7xl">
                    <div className="flex flex-col items-start gap-4 mt-4 flex-nowrap xl:flex-row">
                      <div className="flex flex-col w-full text-gray-700">
                        <div className="max-w-7xl xl:flex xl:items-stretch">
                          <div className="w-full xl:w-2/3">
                            <div>
                              Enter the credentials for the service account and
                              the client id associated with the account
                            </div>
                            <div className="flex mt-3">
                              <TextInput
                                type="text"
                                placeholder="Enter Username"
                                value={values.userName}
                                onChange={handleChange}
                                name="userName"
                              ></TextInput>
                            </div>
                            <div className="flex mt-3">
                              <TextInput
                                type="password"
                                placeholder="Enter Password"
                                value={values.password}
                                onChange={handleChange}
                                name="password"
                              ></TextInput>
                            </div>
                            <div className="flex flex-row-reverse mt-3">
                              {authenticateButton}
                            </div>
                          </div>

                          <div className="w-[1px] h-[inherit] border-r mx-5 border-brown-500"></div>
                          <hr className="w-full col-span-5 my-6 border-1 border-brown-500 xl:hidden" />

                          <div className="w-full xl:w-1/3 sm:min-w-[350px] text-gray-700">
                            <div className="flex flex-col col-span-2 mt-6 xl:mt-0 xl:col-span-1">
                              <div className="mb-2 text-xl font-bold text-black">
                                How it works
                              </div>
                              <ul className="text-sm font-medium list-decimal list-inside">
                                <li className="py-2">
                                  Enter your username and password for your
                                  Azuga account
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {selectedEldSystem?.code === ELD_SYSTEMS.FLEETUP ? (
                      <div className=" max-w-7xl">
                        <div className="flex flex-col items-start gap-4 mt-4 flex-nowrap xl:flex-row">
                          <div className="flex flex-col w-full text-gray-700">
                            <div className="max-w-7xl xl:flex xl:items-stretch">
                              <div className="w-full xl:w-2/3">
                                <div>
                                  Enter the account id, api key and secret key
                                  from your FleetUp account
                                </div>
                                <div className="flex mt-3">
                                  <TextInput
                                    type="text"
                                    placeholder="Enter Account Id"
                                    value={values.acctId}
                                    onChange={handleChange}
                                    name="acctId"
                                  ></TextInput>
                                </div>
                                <div className="flex mt-3">
                                  <TextInput
                                    type="text"
                                    placeholder="Enter Secret"
                                    value={values.secret}
                                    onChange={handleChange}
                                    name="secret"
                                  ></TextInput>
                                </div>
                                <div className="flex mt-3">
                                  <TextInput
                                    type="text"
                                    placeholder="Enter Api Key"
                                    value={values.apiKey}
                                    onChange={handleChange}
                                    name="apiKey"
                                  ></TextInput>
                                </div>

                                <div className="flex flex-col mt-5">
                                  <div className="flex flex-row">
                                    <CheckboxInput
                                      id={`agreement`}
                                      name="agreement"
                                      type="checkbox"
                                      onClick={(e) => setAgreement(!agreement)}
                                      label={"I Accept"}
                                      labelClass="text-sm"
                                    ></CheckboxInput>
                                  </div>
                                  <div className="mt-2 text-xs italic">
                                    Consent for end users to share data with
                                    RoadFlex: by checking the box above and
                                    clicking ["I Accept"], you agree to share
                                    with Openlane Technology Corporation dba
                                    RoadFlex ("RoadFlex"), and you consent to
                                    allowing RoadFlex to share with its third-
                                    party service providers, your name, VIN,
                                    geolocation, and your payment card
                                    transaction data such as transaction date,
                                    applicable merchant, transaction location,
                                    transaction amount.
                                  </div>
                                </div>
                                <div className="flex flex-row-reverse mt-3">
                                  {authenticateButton}
                                </div>
                              </div>

                              <div className="w-[1px] h-[inherit] border-r mx-5 border-brown-500"></div>
                              <hr className="w-full col-span-5 my-6 border-1 border-brown-500 xl:hidden" />

                              <div className="w-full xl:w-1/3 sm:min-w-[350px] text-gray-700">
                                <div className="flex flex-col col-span-2 mt-6 xl:mt-0 xl:col-span-1">
                                  <div className="mb-2 text-xl font-bold text-black">
                                    How it works
                                  </div>
                                  <ul className="text-sm font-medium list-decimal list-inside">
                                    <li className="py-2">
                                      Add account id, secret, API Key provided
                                      by FLEETUP
                                    </li>
                                    <li className="py-2">
                                      Add the service account and connect your
                                      Fleetup Account with RoadFlex
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {selectedEldSystem?.code === ELD_SYSTEMS.VERIZON ? (
                          <div className=" max-w-7xl">
                            <div className="flex flex-col items-start gap-4 mt-4 flex-nowrap xl:flex-row">
                              <div className="flex flex-col w-full text-gray-700">
                                <div className="max-w-7xl xl:flex xl:items-stretch">
                                  <div className="w-full xl:w-2/3">
                                    <div>
                                      Enter the username, password and app ID to
                                      connect verizon on the RoadFlex platform
                                    </div>
                                    <div className="flex mt-3">
                                      <TextInput
                                        type="text"
                                        placeholder="Enter Username"
                                        value={values.userName}
                                        onChange={handleChange}
                                        name="userName"
                                      ></TextInput>
                                    </div>
                                    <div className="flex mt-3">
                                      <TextInput
                                        type="password"
                                        placeholder="Enter Password"
                                        value={values.password}
                                        onChange={handleChange}
                                        name="password"
                                      ></TextInput>
                                    </div>
                                    <div className="flex flex-row-reverse mt-3">
                                      {authenticateButton}
                                    </div>
                                  </div>

                                  <div className="w-[1px] h-[inherit] border-r mx-5 border-brown-500"></div>
                                  <hr className="w-full col-span-5 my-6 border-1 border-brown-500 xl:hidden" />

                                  <div className="w-full xl:w-1/3 sm:min-w-[350px] text-gray-700">
                                    <div className="flex flex-col col-span-2 mt-6 xl:mt-0 xl:col-span-1">
                                      <div className="mb-2 text-xl font-bold text-black">
                                        How it works
                                      </div>
                                      <div className="mb-4 text-sm">
                                        Instructions with screenshots can be
                                        found{" "}
                                        <a
                                          href="https://tinyurl.com/RoadFlex-Verizon"
                                          className="italic font-medium underline"
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          here
                                        </a>
                                        .
                                      </div>
                                      <ul className="text-sm font-medium list-decimal list-inside">
                                        <li className="py-2">
                                          Log into your{" "}
                                          <a
                                            href="https://www.verizonconnect.com/login/"
                                            className="italic font-medium underline"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            Verizon Reveal account
                                          </a>
                                          .
                                        </li>
                                        <li className="py-2">
                                          Once logged in, from the top-right
                                          navigation bar, select your account
                                          profile icon and from the drop-down
                                          menu, click{" "}
                                          <span className="font-semibold">
                                            Marketplace
                                          </span>
                                          .
                                        </li>
                                        <li className="py-2">
                                          Click{" "}
                                          <span className="font-semibold">
                                            API Integrations
                                          </span>{" "}
                                          and{" "}
                                          <span className="font-semibold">
                                            Get Started
                                          </span>
                                          .
                                        </li>
                                        <li className="py-2">
                                          In the data access form, in{" "}
                                          <span className="font-semibold">
                                            Account Details
                                          </span>
                                          , fill in the required information
                                          <ul className="text-sm font-medium list-disc list-inside">
                                            <li>
                                              Third-party company name: input
                                              "RoadFlex"
                                            </li>
                                            <li>
                                              Integration name: input "RoadFlex"
                                            </li>
                                            <li>
                                              Developer name: input "RoadFlex
                                              Developer"
                                            </li>
                                            <li>
                                              Developer email: input your email
                                            </li>
                                          </ul>
                                        </li>
                                        <li className="py-2">
                                          Read the{" "}
                                          <span className="font-semibold">
                                            Data Access Consent Form
                                          </span>
                                          .
                                        </li>
                                        <li className="py-2">
                                          To authorize Verizon Connect to give
                                          the third-party company and developer
                                          access to your data, select the
                                          checkbox. Click{" "}
                                          <span className="font-semibold">
                                            Next
                                          </span>
                                          .
                                        </li>
                                        <li className="py-2">
                                          Click{" "}
                                          <span className="font-semibold">
                                            Confirm Request
                                          </span>
                                          . Once your request is submitted, an
                                          email is sent with the integration
                                          user credentials, next steps and link
                                          to the Verizon Connect Developer
                                          Portal (check your junk or spam
                                          folder).
                                        </li>
                                        <li className="py-2">
                                          You will receive an email with the
                                          subject line "Developer API
                                          integration next steps".
                                        </li>
                                        <li className="py-2">
                                          Use the Username and Password provided
                                          as the credentials to integrate
                                          Verizon Connect to RoadFlex.
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            {selectedEldSystem?.code === ELD_SYSTEMS.NEXTRAQ ? (
                              <div className=" max-w-7xl">
                                <div className="flex flex-col items-start gap-4 mt-4 flex-nowrap xl:flex-row">
                                  <div className="flex flex-col w-full text-gray-700">
                                    <div className="max-w-7xl xl:flex xl:items-stretch">
                                      <div className="w-full xl:w-2/3">
                                        <div className="flex mt-3">
                                          <TextInput
                                            type="text"
                                            placeholder="Enter Username"
                                            value={values.userName}
                                            onChange={handleChange}
                                            name="userName"
                                          ></TextInput>
                                        </div>
                                        <div className="flex mt-3">
                                          <TextInput
                                            type="password"
                                            placeholder="Enter Password"
                                            value={values.password}
                                            onChange={handleChange}
                                            name="password"
                                          ></TextInput>
                                        </div>
                                        <div className="flex mt-3">
                                          <TextInput
                                            type="text"
                                            placeholder="Enter Customer Connect Number"
                                            value={values.acctId}
                                            onChange={handleChange}
                                            name="acctId"
                                          ></TextInput>
                                        </div>
                                        <div className="flex flex-row-reverse mt-3">
                                          {authenticateButton}
                                        </div>
                                      </div>

                                      <div className="w-[1px] h-[inherit] border-r mx-5 border-brown-500"></div>
                                      <hr className="w-full col-span-5 my-6 border-1 border-brown-500 xl:hidden" />

                                      <div className="w-full xl:w-1/3 sm:min-w-[350px] text-gray-700">
                                        <div className="flex flex-col col-span-2 mt-6 xl:mt-0 xl:col-span-1">
                                          <div className="mb-2 text-xl font-bold text-black">
                                            How it works
                                          </div>
                                          <ul className="text-sm font-medium list-decimal list-inside">
                                            <li className="py-2">
                                              Please enter your Username,
                                              Password, and Connect customer
                                              number to connect to NexTraq.
                                            </li>
                                            <li className="py-2">
                                              You can find your Connect customer
                                              number in your NexTraq account.
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <>
                                {selectedEldSystem?.code ===
                                ELD_SYSTEMS.BOUNCIE ? (
                                  <div className=" max-w-7xl">
                                    <div className="flex flex-col items-start gap-4 mt-4 flex-nowrap xl:flex-row">
                                      <div className="flex flex-col w-full text-gray-700">
                                        <div className="max-w-7xl xl:flex xl:items-stretch">
                                          <div className="w-full xl:w-2/3">
                                            <div className="flex mt-3">
                                              {values.token && (
                                                <TextInput
                                                  type="text"
                                                  placeholder="Bouncie Token"
                                                  value={values.token}
                                                  disabled
                                                  name="token"
                                                ></TextInput>
                                              )}
                                              <Button
                                                type={ButtonType.Button}
                                                variant={ButtonVariant.Primary}
                                                size={ButtonSize.AppSize}
                                                className="ml-2"
                                                disabled={isSubmitting}
                                                onClick={(e) =>
                                                  handleBouncieConnect()
                                                }
                                              >
                                                {values.token
                                                  ? "Authenticate"
                                                  : "Login into Bouncie"}
                                              </Button>
                                            </div>
                                          </div>

                                          <div className="w-[1px] h-[inherit] border-r mx-5 border-brown-500"></div>
                                          <hr className="w-full col-span-5 my-6 border-1 border-brown-500 xl:hidden" />

                                          <div className="w-full xl:w-1/3 sm:min-w-[350px] text-gray-700">
                                            <div className="flex flex-col col-span-2 mt-6 xl:mt-0 xl:col-span-1">
                                              <div className="mb-2 text-xl font-bold text-black">
                                                How it works
                                              </div>
                                              <ul className="text-sm font-medium list-decimal list-inside">
                                                <li className="py-2">
                                                  To connect Bouncie, click the
                                                  Login into Bouncie button and
                                                  log in using your username and
                                                  password.
                                                </li>
                                                <li className="py-2">
                                                  Select the vehicles you wish
                                                  to integrate and click
                                                  Approve. Upon successful
                                                  authentication, you will be
                                                  redirected to the Integrations
                                                  page.
                                                </li>
                                                <li className="py-2">
                                                  Check your Bouncie token and
                                                  click on Authenticate to
                                                  complete the integration.
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className=" max-w-7xl">
                                    <div className="flex flex-col items-start gap-4 mt-4 flex-nowrap xl:flex-row">
                                      <div className="flex flex-col w-full text-gray-700">
                                        <div className="max-w-7xl xl:flex xl:items-stretch">
                                          <div className="w-full xl:w-2/3">
                                            <div>
                                              Enter the API token from your UTH
                                              account
                                            </div>
                                            <div className="flex mt-3">
                                              <TextInput
                                                type="text"
                                                placeholder="Enter token"
                                                value={values.token}
                                                onChange={handleChange}
                                                name="token"
                                              ></TextInput>
                                            </div>
                                            <div className="flex flex-row-reverse mt-3">
                                              {authenticateButton}
                                            </div>
                                          </div>

                                          <div className="w-[1px] h-[inherit] border-r mx-5 border-brown-500"></div>
                                          <hr className="w-full col-span-5 my-6 border-1 border-brown-500 xl:hidden" />

                                          <div className="w-full xl:w-1/3 sm:min-w-[350px] text-gray-700">
                                            <div className="flex flex-col col-span-2 mt-6 xl:mt-0 xl:col-span-1">
                                              <div className="mb-2 text-xl font-bold text-black">
                                                How it works
                                              </div>
                                              <ul className="text-sm font-medium list-decimal list-inside">
                                                <li className="py-2">
                                                  Create an API token via the
                                                  UTH account/platform
                                                </li>
                                                <li className="py-2">
                                                  Connect to the UTH account
                                                  using the API token
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </Dialog>
  );
}
