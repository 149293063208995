/* eslint-disable max-lines */
/** @format */
import { gql } from "@apollo/client";

//landing Page
export const CREATE_SUBSCRIBER_LANDING_PAGE = gql`
  mutation CREATE_SUBSCRIBER_LANDING_PAGE($data: CreateSubscriberInput!) {
    createSubscriber(data: $data) {
      code
      message
    }
  }
`;
export const SIGNUP_VERIFY = gql`
  mutation SIGNUP_VERIFY($data: SignUpInput!) {
    signupVerify(data: $data)
  }
`;
export const SIGN_UP = gql`
  mutation SIGN_UP($data: SignUpInput!) {
    signup(data: $data) {
      id
      authToken
    }
  }
`;
export const SIGN_IN = gql`
  mutation SIGN_IN($data: signInInput!) {
    signin(data: $data) {
      authToken
      isDeviceAuthorized
    }
  }
`;
export const VERIFY_DEVICE_AND_SIGN_IN = gql`
  mutation VERIFY_DEVICE_AND_SIGN_IN($data: verifyDeviceAndSignInInput!) {
    verifyDeviceAndSignIn(data: $data) {
      authToken
      deviceToken
      isDeviceAuthorized
    }
  }
`;
export const EMAIL_VERIFICATION = gql`
  mutation EMAIL_VERIFICATION($token: String!) {
    emailVerification(token: $token) {
      success
      error
    }
  }
`;
export const SET_PASSWORD = gql`
  mutation SET_PASSWORD($data: SetPasswordInput!) {
    setPassword(data: $data) {
      code
      message
    }
  }
`;
export const PASSWORD_FORGOT = gql`
  mutation PASSWORD_FORGOT($data: EmailInput!) {
    passwordForgot(data: $data) {
      code
      message
    }
  }
`;
export const PASSWORD_RESET = gql`
  mutation PASSWORD_RESET($data: ResetPasswordInput!) {
    passwordReset(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_USER_PASSWORD = gql`
  mutation UPDATE_USER_PASSWORD($data: UpdateUserPasswordInput!) {
    updateUserPassword(data: $data) {
      code
      message
    }
  }
`;
//admin
export const EXECUTE_ADMIN_PRESET_QUERY = gql`
  mutation EXECUTE_ADMIN_PRESET_QUERY($data: ExecuteAdminPresetQueryInput) {
    executeAdminPresetQuery(data: $data) {
      queryResults {
        companyName
        fullName
        email
        ein
        dot
        mc
        approvedDate
        cardHolderStatus
        accountStatus
        outstandingAmount
        failureReason
        failureMessage
        failureCode
      }
      message
      code
    }
  }
`;

export const EXECUTE_ADMIN_PRESET_QUERY_FOR_ALL_USERS_DATA = gql`
  mutation EXECUTE_ADMIN_PRESET_QUERY_FOR_ALL_USERS_DATA(
    $data: ExecuteAdminPresetQueryInput
  ) {
    executeAdminPresetQuery(data: $data) {
      queryResults {
        companyName
        email
        ein
        dot
        mc
      }
      message
      code
    }
  }
`;

export const GET_CREDIT_TO_PREPAID = gql`
  mutation GET_CREDIT_TO_PREPAID($data: EmailInput) {
    moveCreditToPrepaid(data: $data) {
      success
      error
    }
  }
`;
export const ADMIN_IMPERSONATOR_SIGN_IN = gql`
  mutation ADMIN_IMPERSONATOR_SIGN_IN($data: EmailInput!) {
    impersonatorSignIn(data: $data) {
      authToken
    }
  }
`;

export const SAVE_PLAID_INFORMATION = gql`
  mutation SAVE_PLAID_INFORMATION($input: PlaidBasicInput!) {
    savePlaidInformation(input: $input) {
      success
      code
      type
      message
    }
  }
`;

export const USER_UPDATE = gql`
  mutation USER_UPDATE($data: UserUpdateInput!) {
    userUpdate(data: $data) {
      code
      message
    }
  }
`;

export const CREATE_AND_UPDATE_OWNERS = gql`
  mutation CREATE_AND_UPDATE_OWNERS($data: CreateAndUpdateOwnersInput!) {
    createAndUpdateOwners(data: $data) {
      code
      message
    }
  }
`;

export const REMOVE_OWNER_AND_EXECUTIVE = gql`
  mutation REMOVE_OWNER_AND_EXECUTIVE($data: RemoveOwnerAndExecutiveInput!) {
    removeOwnerAndExecutive(data: $data) {
      code
      message
    }
  }
`;

export const UPDATE_ONBOARD_STATUS = gql`
  mutation UPDATE_ONBOARD_STATUS($data: UpdateOnboardStatusInput!) {
    updateOnboardStatus(data: $data)
  }
`;

export const SAVE_AUTO_PAYMENT = gql`
  mutation SAVE_AUTO_PAYMENT($data: AutoPaymentInput!) {
    autoPaymentOption(data: $data) {
      success
      error
    }
  }
`;
//App: Payment methods page
export const DELETE_BANK_ACCOUNT = gql`
  mutation DELETE_BANK_ACCOUNT($input: IsPrimaryAccountInput!) {
    deleteBankAccount(input: $input)
  }
`;
export const MAKE_PRIMARY_BANK_ACCOUNT = gql`
  mutation MAKE_PRIMARY_BANK_ACCOUNT($input: IsPrimaryAccountInput!) {
    setIsPrimaryAccount(input: $input)
  }
`;
export const CHECK_ACCOUNT_BALANCE = gql`
  mutation CHECK_ACCOUNT_BALANCE($input: CheckAccountBalanceInput!) {
    checkAccountBalance(input: $input) {
      bankBalance
    }
  }
`;
export const SAVE_PLAID_INFO_PAYMENT = gql`
  mutation SAVE_PLAID_INFO_PAYMENT($input: PlaidBasicInput!) {
    savePlaidInformationFromPayment(input: $input) {
      code
      message
    }
  }
`;

export const ADD_PLAID_PENDING_CONNECTION = gql`
  mutation ADD_PLAID_PENDING_CONNECTION(
    $data: AddPlaidPendingConnectionInput!
  ) {
    addPlaidPendingConnection(data: $data) {
      success
      code
      type
      message
    }
  }
`;

export const DELETE_PLAID_PENDING_CONNECTION = gql`
  mutation DELETE_PLAID_PENDING_CONNECTION(
    $data: DeletePlaidPendingConnectionInput!
  ) {
    deletePlaidPendingConnection(data: $data) {
      success
      code
      type
      message
    }
  }
`;

export const READ_BILLING_SUMMARY = gql`
  mutation READ_BILLING_SUMMARY($data: ReadBillingSummaryInput!) {
    readBillingSummary(data: $data) {
      billingCycleId
      startingDate
      endingDate
      spendings
      rewards
      lateFees
      outstandingAmount
      billingCycleStatus
      billingCycleInvoiceId
      paymentTerms
      createdManually
      fileURL
      billingCycleItems {
        id
        itemName
        itemAmount
        order
      }
      transactionDetails {
        lastFour
        fleetDriverName
        fleetDriverEmail
        transactions {
          date
          merchantName
          merchantState
          merchantPostCd
          user
          amount
          merchantCategory
          status
          vehicleName
          vehicleTag
          driverTag
          cardTag
          transactionTag
        }
      }
      company {
        businessName
        businessDesc
        street
        streetDetail
        city
        zip
        ein
      }
    }
  }
`;

export const ADMIN_PREFILL_ONBOARD = gql`
  mutation ADMIN_PREFILL_ONBOARD($data: AdminOnboardInput!) {
    adminOnboard(data: $data) {
      code
      message
    }
  }
`;

export const ADMIN_PREFILL_ONBOARD_RESEND_EMAIL = gql`
  mutation ADMIN_PREFILL_ONBOARD_RESEND_EMAIL($data: EmailInput!) {
    adminOnboardResendEmail(data: $data) {
      code
      message
    }
  }
`;

export const ENTERPRISE_APPLICATION = gql`
  mutation ENTERPRISE_APPLICATION($data: EnterpriseApplicationInput) {
    enterpriseOnboard(data: $data) {
      code
      message
    }
  }
`;

export const SUBMIT_ONBOARD_APPLICATION = gql`
  mutation SUBMIT_ONBOARD_APPLICATION($data: SubmitOnBoardInput) {
    submitOnboardApplication(data: $data)
  }
`;

export const ADMIN_SIGN_IN = gql`
  mutation ADMIN_SIGN_IN($data: AdminSignInInput!) {
    adminSignin(data: $data) {
      code
      message
      id
      authToken
      firstName
      lastName
      roles
    }
  }
`;

export const BUSINESS_APPROVED = gql`
  mutation BUSINESS_APPROVED($data: BusinessApprovedInput!) {
    businessApproved(data: $data) {
      success
      error
    }
  }
`;

export const MOVE_APPROVED_BUSINESS_TO_PREPAID = gql`
  mutation MOVE_APPROVED_BUSINESS_TO_PREPAID(
    $data: MoveApprovedBusinessToPrepaidInput!
  ) {
    moveApprovedBusinessToPrepaid(data: $data) {
      code
      message
    }
  }
`;

export const UPDATE_ACCOUNT_STATUS = gql`
  mutation UPDATE_ACCOUNT_STATUS($data: UpdateAccountStatusInput) {
    updateAccountStatus(data: $data) {
      code
      message
    }
  }
`;

export const USER_UPDATE_BY_ADMIN = gql`
  mutation USER_UPDATE_BY_ADMIN($data: UpdateUserDetailsInput!) {
    updateUserDetails(data: $data) {
      code
      message
    }
  }
`;

export const CREATE_USER_CREDIT_BY_ADMIN = gql`
  mutation CREATE_USER_CREDIT_BY_ADMIN($data: CreateUserCreditByAdminInput!) {
    createUserCreditByAdmin(data: $data) {
      code
      message
    }
  }
`;

export const UPDATE_ADMIN_CARD_SETTING = gql`
  mutation UPDATE_ADMIN_CARD_SETTING($data: UpdateAdminCardSettingInput!) {
    updateAdminCardSetting(data: $data) {
      message
      code
    }
  }
`;

export const UPDATE_BLOCK_MERCHANTS_STATES = gql`
  mutation UPDATE_BLOCK_MERCHANTS_STATES($data: BlockMerchantsStatesInput!) {
    updateBlockedMerchantsStates(data: $data) {
      code
      message
    }
  }
`;

export const UPDATE_BLOCK_MERCHANT_BY_MERCHANT_ID = gql`
  mutation UPDATE_BLOCK_MERCHANT_BY_MERCHANT_ID(
    $data: UpdateBlockedMerchantsNetworkIdsInput!
  ) {
    updateBlockedMerchantsNetworkIds(data: $data) {
      code
      message
    }
  }
`;

export const UPDATE_TRANSACTION_MEMO = gql`
  mutation UPDATE_TRANSACTION_MEMO($data: updateTransactionMemoInput!) {
    updateTransactionMemo(data: $data) {
      code
      message
    }
  }
`;

export const REMOVE_TRANSACTION_RECEIPT = gql`
  mutation REMOVE_TRANSACTION_RECEIPT($data: RemoveTransactionReceiptInput!) {
    removeTransactionReceipt(data: $data) {
      code
      message
    }
  }
`;

export const WAIVE_TRANSACTION_RECEIPT_REQUIREMENT = gql`
  mutation WAIVE_TRANSACTION_RECEIPT_REQUIREMENT(
    $data: WaiveTransactionReceiptRequirementInput!
  ) {
    waiveTransactionReceiptRequirement(data: $data) {
      code
      message
    }
  }
`;

export const DRIVER_UPDATE_TRANSACTION_MEMO = gql`
  mutation DRIVER_UPDATE_TRANSACTION_MEMO($data: updateTransactionMemoInput!) {
    driverUpdateTransactionMemo(data: $data) {
      code
      message
    }
  }
`;

export const IMPORT_TRANSACTIONS = gql`
  mutation IMPORT_TRANSACTIONS($data: ImportTransactionInput!) {
    importTransactions(data: $data) {
      code
      message
    }
  }
`;

export const CREATE_ADMIN_COMMENT = gql`
  mutation CREATE_ADMIN_COMMENT($data: CreateAdminCommentInput!) {
    createAdminComment(data: $data) {
      code
      message
    }
  }
`;

export const UPDATE_ADMIN_COMMENT = gql`
  mutation UPDATE_ADMIN_COMMENT($data: UpdateAdminCommentInput!) {
    updateAdminComment(data: $data) {
      code
      message
    }
  }
`;

export const ADMIN_UPDATE_CARDHOLDER_STATUS = gql`
  mutation ADMIN_UPDATE_CARDHOLDER_STATUS(
    $data: AdminUpdateCardholderStatusInput!
  ) {
    adminUpdateCardholderStatus(data: $data) {
      message
      code
    }
  }
`;

export const CREATE_MANUAL_PAYMENT_INTENT = gql`
  mutation CREATE_MANUAL_PAYMENT_INTENT($data: ManuallyPaymentInput!) {
    createManualPaymentIntent(data: $data) {
      clientSecret
    }
  }
`;

export const STRIPE_ADD_NEW_PAYMENT_METHOD = gql`
  mutation STRIPE_ADD_NEW_PAYMENT_METHOD {
    stripeAddNewPaymentMethod {
      clientSecret
    }
  }
`;

export const STRIPE_CHANGE_PAYMENT_METHOD = gql`
  mutation STRIPE_CHANGE_PAYMENT_METHOD(
    $data: stripeChangePaymentMethodInput!
  ) {
    stripeChangePaymentMethod(data: $data)
  }
`;

export const STRIPE_DELETE_PAYMENT_METHOD = gql`
  mutation STRIPE_DELETE_PAYMENT_METHOD(
    $data: stripeDeletePaymentMethodInput!
  ) {
    stripeDeletePaymentMethod(data: $data)
  }
`;

export const STRIPE_CONFIRM_SETUP_INTENT = gql`
  mutation STRIPE_CONFIRM_SETUP_INTENT($data: StripeConfirmSetupIntentInput!) {
    stripeConfirmSetupIntent(data: $data) {
      code
      message
    }
  }
`;

export const GET_MANUAL_PAYMENT_STATUS = gql`
  mutation GET_MANUAL_PAYMENT_STATUS($data: getManualPaymentStatusInput!) {
    getManualPaymentStatus(data: $data) {
      status
      message
    }
  }
`;

export const REFUND_PAYMENT = gql`
  mutation REFUND_PAYMENT($data: RefundPaymentInput!) {
    refundPayment(data: $data) {
      success
      error
    }
  }
`;

export const UPDATE_ADDITIONAL_MERCHANT_CATEGORIES = gql`
  mutation UPDATE_ADDITIONAL_MERCHANT_CATEGORIES(
    $data: UpdateAdditionalMerchantCategoriesInput!
  ) {
    updateAdditionalMerchantCategories(data: $data) {
      code
      message
    }
  }
`;

export const UPDATE_RISK_STATUS = gql`
  mutation UPDATE_RISK_STATUS($data: UpdateRiskStatusInput!) {
    updateRiskStatus(data: $data) {
      code
      message
    }
  }
`;

export const UPDATE_DEFAULT_STATUS = gql`
  mutation UPDATE_DEFAULT_STATUS($data: UpdateDefaultStatusInput!) {
    defaultUserSettings(data: $data) {
      code
      message
    }
  }
`;

//landing page
export const LANDING_PAGE_FORM_SUBMISSION = gql`
  mutation LANDING_PAGE_FORM_SUBMISSION(
    $data: LandingPageFormSubmissionInput!
  ) {
    landingPageFormSubmission(data: $data) {
      code
      message
    }
  }
`;

//app: parent company page
export const CREATE_PARENT_COMPANY_USER = gql`
  mutation CREATE_PARENT_COMPANY_USER($data: CreateParentCompanyUserInput!) {
    createParentCompanyUser(data: $data) {
      message
      code
    }
  }
`;

//app: drivers page
export const CREATE_DRIVER = gql`
  mutation CREATE_DRIVER($data: CreateUpdateDriverInput) {
    createDriver(data: $data) {
      code
      message
    }
  }
`;
export const CREATE_MULTIPLE_DRIVERS = gql`
  mutation CREATE_MULTIPLE_DRIVERS($data: CreateMultipleDriversInput) {
    createMultipleDrivers(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_DRIVER = gql`
  mutation UPDATE_DRIVER($data: CreateUpdateDriverInput) {
    updateDriver(data: $data) {
      code
      message
    }
  }
`;
export const DELETE_DRIVERS = gql`
  mutation DELETE_DRIVERS($data: IdInputList) {
    deleteDrivers(data: $data) {
      code
      message
    }
  }
`;
export const INVITE_DRIVER_TO_APP = gql`
  mutation INVITE_DRIVER_TO_APP($data: IdInputList) {
    inviteDriverToApp(data: $data) {
      code
      message
    }
  }
`;

//app: vehicles page
export const CREATE_VEHICLE = gql`
  mutation CREATE_VEHICLE($data: CreateUpdateFleetInput) {
    createVehicle(data: $data) {
      code
      message
    }
  }
`;
export const CREATE_MULTIPLE_VEHICLES = gql`
  mutation CREATE_MULTIPLE_VEHICLES($data: CreateMultipleVehiclesInput) {
    createMultipleVehicles(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_VEHICLE = gql`
  mutation UPDATE_VEHICLE($data: CreateUpdateFleetInput) {
    updateVehicle(data: $data) {
      code
      message
    }
  }
`;
export const DELETE_VEHICLE = gql`
  mutation DELETE_VEHICLE($data: IdInputList) {
    deleteVehicles(data: $data) {
      code
      message
    }
  }
`;
//app: onboard
export const UPLOAD_DOCUMENTS = gql`
  mutation UPLOAD_DOCUMENTS($data: DocumentInput!) {
    uploadDocuments(data: $data) {
      code
      message
    }
  }
`;
//app: account tags
export const CREATE_ACCOUNT_TAG = gql`
  mutation CREATE_ACCOUNT_TAG($data: CreateAccountTagInput!) {
    createAccountTag(data: $data) {
      message
      code
    }
  }
`;
export const UPDATE_ACCOUNT_TAG = gql`
  mutation UPDATE_ACCOUNT_TAG($data: UpdateAccountTagInput!) {
    updateAccountTag(data: $data) {
      message
      code
    }
  }
`;
export const DELETE_ACCOUNT_TAG = gql`
  mutation DELETE_ACCOUNT_TAG($data: DeleteAccountTagInput!) {
    deleteAccountTag(data: $data) {
      message
      code
    }
  }
`;
export const ASSIGN_ACCOUNT_TAG = gql`
  mutation ASSIGN_ACCOUNT_TAG($data: AssignAccountTagInput!) {
    assignAccountTag(data: $data) {
      message
      code
    }
  }
`;
export const APPLY_CARD_TAG = gql`
  mutation APPLY_CARD_TAG($data: ApplyCardTagInput!) {
    applyCardTag(data: $data) {
      message
      code
    }
  }
`;
//app: cards page
export const CREATE_CARD = gql`
  mutation CREATE_CARD($data: CreateCardInput!) {
    createCard(data: $data) {
      code
      message
    }
  }
`;
export const ORDER_PHYSICAL_CARD = gql`
  mutation ORDER_PHYSICAL_CARD($data: OrderPhysicalCardInput!) {
    orderPhysicalCardFromVirtualCard(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_CARD_DETAILS = gql`
  mutation UPDATE_CARD_DETAILS($data: CardDetailUpdateInput!) {
    updateCardDetails(data: $data) {
      code
      message
      cardUpdated
    }
  }
`;
export const UPDATE_CARD_PIN = gql`
  mutation UPDATE_CARD_PIN($data: UpdateCardPinInput!) {
    updateCardPin(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_MULTIPLE_CARDS_STATUS = gql`
  mutation UPDATE_MULTIPLE_CARDS_STATUS(
    $data: UpdateMultipleCardsStatusInput!
  ) {
    updateMultipleCardsStatus(data: $data) {
      code
      message
      cardUpdated
    }
  }
`;
//app: policy page
export const CREATE_UPDATE_POLICY = gql`
  mutation CREATE_UPDATE_POLICY($data: CreateUpdateCardRuleInput!) {
    createUpdateCardRule(data: $data) {
      code
      message
    }
  }
`;
export const DELETE_CARD_POLICY = gql`
  mutation DELETE_CARD_POLICY($data: DeleteCardPolicyInput) {
    deleteCardPolicy(data: $data) {
      code
      message
    }
  }
`;
export const APPLY_CARD_POLICY = gql`
  mutation APPLY_CARD_POLICY($data: ApplyCardRuleInput) {
    applyCardPolicy(data: $data) {
      code
      message
    }
  }
`;
//app: alerts
export const UPDATE_ALERTS = gql`
  mutation UPDATE_ALERTS($data: updateAlertsInput) {
    updateAlerts(data: $data) {
      message
      code
    }
  }
`;
export const DELETE_ALERTS = gql`
  mutation DELETE_ALERTS($data: IdInputRequired) {
    deleteAlerts(data: $data) {
      message
      code
    }
  }
`;
export const DRIVER_UPDATE_ALERTS = gql`
  mutation DRIVER_UPDATE_ALERTS($data: updateAlertsInput) {
    driverUpdateAlerts(data: $data) {
      message
      code
    }
  }
`;
export const DRIVER_DELETE_ALERTS = gql`
  mutation DELETE_ALERTS($data: IdInputRequired) {
    driverDeleteAlerts(data: $data) {
      message
      code
    }
  }
`;

//app: notifications page
export const READ_USER_NOTIFICATIONS = gql`
  mutation READ_USER_NOTIFICATIONS($data: IdInput) {
    readUserNotifications(data: $data) {
      userNotifications {
        id
        name
        category
        description
        rank
        additionalUserDetailId
        otherUserDetailId
        optionValues
        communicationMode
        isSmsDisabled
        isEmailDisabled
        variables
      }
      code
      message
    }
  }
`;
export const UPDATE_USER_NOTIFICATIONS = gql`
  mutation UPDATE_USER_NOTIFICATIONS($data: updateUserNotificationsInput) {
    updateUserNotifications(data: $data) {
      code
      message
    }
  }
`;

//app: account settings page
export const UPDATE_EMAIL_OR_PHONE_WITH_VERIFICATION_CODE = gql`
  mutation UPDATE_EMAIL_OR_PHONE_WITH_VERIFICATION_CODE(
    $data: EmailOrPhoneOTPInput!
  ) {
    updateEmailOrPhoneWithVerificationCode(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_BUSINESS_INFORMATION = gql`
  mutation UPDATE_BUSINESS_INFORMATION($data: UpdateBusinessInformationInput!) {
    updateBusinessInformation(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_FLEET_INFORMATION = gql`
  mutation UPDATE_FLEET_INFORMATION($data: UpdateFleetInformationInput!) {
    updateFleetInformation(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_BUSINESS_ADRESS = gql`
  mutation UPDATE_BUSINESS_ADRESS($data: UpdateBusinessAddressInput!) {
    updateBusinessAddress(data: $data) {
      code
      message
    }
  }
`;
export const CREATE_AND_UPDATE_HOME_ADDRESS = gql`
  mutation CREATE_AND_UPDATE_HOME_ADDRESS(
    $data: CreateAndUpdateHomeAddressInput!
  ) {
    createAndUpdateHomeAddress(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_ADDITIONAL_BUSINESS_INFORMATION = gql`
  mutation UPDATE_ADDITIONAL_BUSINESS_INFORMATION(
    $data: UpdateAdditionalBusinessInformationInput!
  ) {
    updateAdditionalBusinessInformation(data: $data) {
      code
      message
    }
  }
`;

export const SEND_VERIFICATION_EMAIL_OR_SMS = gql`
  mutation SEND_VERIFICATION_EMAIL_OR_SMS($data: EmailOrPhoneInput!) {
    sendVerificationEmailOrSms(data: $data) {
      message
      code
    }
  }
`;
export const UPDATE_ACCOUNT_DISPLAY_SETTING = gql`
  mutation UPDATE_ACCOUNT_DISPLAY_SETTING(
    $data: UpdateAccountDisplaySettingInput!
  ) {
    updateAccountDisplaySetting(data: $data) {
      message
      code
    }
  }
`;

export const CREATE_AUTHORIZATION_BEARER = gql`
  mutation CREATE_AUTHORIZATION_BEARER {
    createAuthorizationBearer {
      code
      message
    }
  }
`;

export const DELETE_AUTHORIZATION_BEARER = gql`
  mutation DELETE_AUTHORIZATION_BEARER {
    deleteAuthorizationBearer {
      code
      message
    }
  }
`;
//app: multiple roles page
export const CREATE_ACCOUNT_USER = gql`
  mutation CREATE_ACCOUNT_USER($data: CreateAccountUserInput) {
    createAccountUser(data: $data) {
      message
      code
    }
  }
`;
export const UPDATE_ACCOUNT_USER = gql`
  mutation UPDATE_ACCOUNT_USER($data: CreateAccountUserInput) {
    updateAccountUser(data: $data) {
      message
      code
    }
  }
`;
export const CREATE_ACCOUNT_USER_RESEND_INVITATION = gql`
  mutation CREATE_ACCOUNT_USER_RESEND_INVITATION($data: IdInput) {
    createAccountUserResendNotification(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_ACCOUNT_USER_VERIFY = gql`
  mutation UPDATE_ACCOUNT_USER_VERIFY($data: UpdateAccountUserVerifyInput) {
    updateAccountUserVerify(data: $data) {
      code
      message
    }
  }
`;
export const DELETE_ACCOUNT_USER = gql`
  mutation DELETE_ACCOUNT_USER($data: IdInputList) {
    deleteAccountUser(data: $data) {
      message
      code
    }
  }
`;
//app: referral page
export const SHARE_REFERRAL_CODE = gql`
  mutation SHARE_REFERRAL_CODE($data: ShareReferralCodeInput!) {
    shareReferralCode(data: $data) {
      code
      message
    }
  }
`;

//Admin: approved customers - create parent company page
export const CREATE_PARENT_COMPANY = gql`
  mutation CREATE_PARENT_COMPANY($data: CreateParentCompanyInput) {
    createParentCompany(data: $data) {
      message
      code
    }
  }
`;

//Admin: payments page
export const PENDING_PAYMENTS_LIST = gql`
  mutation PENDING_PAYMENTS_LIST($data: PendingACHPaymentListInput) {
    pendingPaymentList(data: $data) {
      businessName
      email
      amount
      paymentId
      failureCode
      additionalInfo
      status
      createdAt
    }
  }
`;

export const MANUAL_PAYMENTS_LIST = gql`
  mutation MANUAL_PAYMENTS_LIST($data: PendingManualPaymentListInput) {
    manualPaymentList(data: $data) {
      businessName
      email
      amount
      paymentId
      failureCode
      additionalInfo
      status
      createdAt
    }
  }
`;

//admin: global settings page
export const UPDATE_ADMIN_BONUS_AND_FUEL_DISCOUNT = gql`
  mutation UPDATE_ADMIN_BONUS_AND_FUEL_DISCOUNT(
    $data: updateFuelDiscountAndBonusInput!
  ) {
    updateFuelDiscountAndBonus(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_ADMIN_APPROVE_UNDERWRITING_AUTOMATION = gql`
  mutation UPDATE_ADMIN_APPROVE_UNDERWRITING_AUTOMATION(
    $data: UpdateAdminUnderwritingAutomationInput!
  ) {
    updateAdminUnderwritingAutomation(data: $data) {
      code
      message
    }
  }
`;
//Admin: notifications page
export const CREATE_UPDATE_NOTIFICATION_WORKFLOW = gql`
  mutation CREATE_UPDATE_NOTIFICATION_WORKFLOW(
    $data: NotificationWorkflowInput!
  ) {
    createUpdateNotificationWorkflow(data: $data) {
      message
      code
    }
  }
`;
export const CREATE_UPDATE_ADMIN_EMAIL_TEMPLATE = gql`
  mutation CREATE_UPDATE_ADMIN_EMAIL_TEMPLATE(
    $data: CreateUpdateAdminEmailTemplateInput!
  ) {
    createUpdateAdminEmailTemplate(data: $data) {
      code
      message
    }
  }
`;

export const DELETE_ADMIN_EMAIL_TEMPLATE = gql`
  mutation DELETE_ADMIN_EMAIL_TEMPLATE($data: IdInputList!) {
    deleteAdminEmailTemplate(data: $data) {
      code
      message
    }
  }
`;

export const CREATE_UPDATE_MAIL_TYPE = gql`
  mutation CREATE_UPDATE_MAIL_TYPE($data: CreateUpdateMailTypeInput!) {
    createUpdateMailType(data: $data) {
      code
      message
    }
  }
`;

export const TEST_EMAIL_OR_SMS_TEMPLATE = gql`
  mutation TEST_EMAIL_OR_SMS_TEMPLATE($data: TestEmailOrSmsTemplateInput!) {
    testEmailOrSmsTemplate(data: $data) {
      code
      message
    }
  }
`;

//Admin: blogs page
export const CREATE_AND_UPDATE_ADMIN_BLOG = gql`
  mutation CREATE_AND_UPDATE_ADMIN_BLOG($data: AdminBlogObjectInput!) {
    createAndUpdateAdminBlog(data: $data) {
      message
      code
    }
  }
`;
export const DELETE_ADMIN_BLOGS = gql`
  mutation DELETE_ADMIN_BLOGS($data: IdInputList!) {
    deleteAdminBlogs(data: $data) {
      code
      message
    }
  }
`;
//Admin: risks page
export const UPDATE_ADMIN_UNDERWRITING_SETTING = gql`
  mutation UPDATE_ADMIN_UNDERWRITING_SETTING(
    $data: UpdateAdminUnderwritingSettingInput!
  ) {
    updateAdminUnderwritingSetting(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_ADMIN_APPROVE_UNDERWRITING_DATA = gql`
  mutation UPDATE_ADMIN_APPROVE_UNDERWRITING_DATA(
    $data: UpdateAdminApproveUnderwritingDataInput!
  ) {
    updateAdminApproveUnderwritingData(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_ADMIN_REJECT_UNDERWRITING_DATA = gql`
  mutation UPDATE_ADMIN_REJECT_UNDERWRITING_DATA(
    $data: UpdateAdminRejectUnderwritingDataInput!
  ) {
    updateAdminRejectUnderwritingData(data: $data) {
      code
      message
    }
  }
`;
//Admin: collections page
export const UPDATE_BUSINESS_COLLECTION_ADMIN = gql`
  mutation UPDATE_BUSINESS_COLLECTION_ADMIN(
    $data: UpdateBusinessCollectionAdminInput!
  ) {
    updateBusinessCollectionAdmin(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_COLLECTION_WORKFLOW_SETTINGS = gql`
  mutation UPDATE_COLLECTION_WORKFLOW_SETTINGS(
    $data: CollectionWorkflowSettingsInput
  ) {
    updateCollectionWorkflowSettings(data: $data) {
      code
      message
    }
  }
`;
export const DELETE_COLLECTION_WORKFLOW = gql`
  mutation DELETE_COLLECTION_WORKFLOW($data: IdInputList!) {
    deleteCollectionWorkflow(data: $data) {
      code
      message
    }
  }
`;

//Admin: team page
export const CREATE_ADMIN = gql`
  mutation CREATE_ADMIN($data: CreateAdminInput!) {
    createAdmin(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_ADMIN = gql`
  mutation UPDATE_ADMIN($data: UpdateAdminInput!) {
    updateAdmin(data: $data) {
      code
      message
    }
  }
`;
export const DELETE_ADMIN = gql`
  mutation DELETE_ADMIN($data: IdInputList!) {
    deleteAdmin(data: $data) {
      code
      message
    }
  }
`;

//admin: Account settings page
export const LOGOUT_ALL_USERS = gql`
  mutation LOGOUT_ALL_USERS($data: LogoutAllUsersInput!) {
    logoutAllUsers(data: $data) {
      code
      message
    }
  }
`;
export const UPDATE_ADMIN_PASSWORD = gql`
  mutation UPDATE_ADMIN_PASSWORD($data: UpdateAdminPasswordInput!) {
    updateAdminPassword(data: $data) {
      code
      message
    }
  }
`;

// GeoTab
export const GEOTAB_AUTHENTICATE = gql`
  mutation GEOTAB_AUTHENTICATE($data: eldAuthenticateInput) {
    eldAuthenticate(data: $data) {
      sessionId
    }
  }
`;
export const GEOTAB_GET_DEVICES = gql`
  mutation GEOTAB_GET_DEVICES($data: getEldDevicesInput) {
    getEldDevices(data: $data) {
      eldDeviceDetailsId
      id
      deviceName
      activeTo
      activeFrom
      deviceType
      isActiveTrackingEnabled
      serialNumber
      vehicleIdentificationNumber
      fleetName
      fleetId
      fleetVin
    }
  }
`;
export const ELD_GET_CREDENTIALS = gql`
  mutation ELD_GET_CREDENTIALS($data: eldCredentialInput) {
    eldCredential(data: $data) {
      code
      success
      type
      message
      detail
      help
      credential {
        database
        password
        userName
        sessionId
        lastAuthenticated
      }
    }
  }
`;
export const GEOTAB_GET_TRIP_DETAILS = gql`
  mutation GEOTAB_GET_TRIP_DETAILS($data: eldDeviceTripDetailsInput) {
    eldDeviceTripDetails(data: $data) {
      fromVersion
      eldDeviceTripDetails {
        start
        stop
        distance
        drivingDuration
        nextTripStart
      }
    }
  }
`;
export const GEOTAB_GET_LIVE_LOCATION = gql`
  mutation GEOTAB_GET_LIVE_LOCATION($data: eldDeviceLiveLocationInput) {
    eldDeviceLiveLocation(data: $data) {
      deviceId
      currentStateDuration
      dateTime
      driver
      latitude
      longitude
      isDriving
      isHistoricLastDriver
      deviceName
    }
  }
`;
export const GEOTAB_GET_TRIP_HISTORY = gql`
  mutation GEOTAB_GET_TRIP_HISTORY($data: eldDeviceTripHistoryInput) {
    eldDeviceTripHistory(data: $data) {
      fromVersion
      eldDeviceTripHistory {
        dateTime
        latitude
        longitude
        speed
      }
    }
  }
`;
export const LINK_ELD_TO_VEHICLE = gql`
  mutation LINK_ELD_TO_VEHICLE($data: LinkEldDeviceInput!) {
    linkEldDeviceToVehicle(data: $data) {
      result
    }
  }
`;

export const ADMIN_DOWNLOAD_LOGS = gql`
  mutation ADMIN_DOWNLOAD_LOGS($data: DownloadLogsInput!) {
    adminDownloadActivityLogs(data: $data) {
      adminLogs {
        createdAt
        activityType
        activityDetails
        activity
        adminId
        id
        admin {
          firstName
          lastName
          email
        }
      }
      userLogs {
        id
        userId
        activity
        activityType
        activityDetails
        createdAt
        user {
          firstName
          lastName
          email
          fullName
        }
        parent {
          firstName
          lastName
          email
          fullName
        }
      }
      code
      message
    }
  }
`;

export const ADMIN_DOWNLOAD_EMAIL_HISTORY_LOGS = gql`
  mutation ADMIN_DOWNLOAD_EMAIL_HISTORY_LOGS(
    $data: DownloadEmailHistoryLogsInput!
  ) {
    adminDownloadEmailHistoryLogs(data: $data) {
      emailHistoryLogs {
        email
        status
        messageId
        result
        emailType
        variables
        createdAt
        updatedAt
      }
      code
      message
    }
  }
`;

export const UPDATE_CUSTOMER_SUPPORT_SALES_CUSTOMER_TIER = gql`
  mutation UPDATE_CUSTOMER_SUPPORT_SALES_CUSTOMER_TIER(
    $data: UpdateCustomerSupportSalesCustomerTierInput!
  ) {
    updateCustomerSupportSalesCustomerTier(data: $data) {
      code
      message
    }
  }
`;

export const CREATE_ENTERPRISE_INVOICE = gql`
  mutation CREATE_ENTERPRISE_INVOICE($data: CreateEnterpriseInvoiceInput!) {
    createEnterpriseInvoice(data: $data) {
      code
      message
    }
  }
`;

export const ARCHIVE_ENTERPRISE_INVOICE = gql`
  mutation ARCHIVE_ENTERPRISE_INVOICE($data: IdAndEmailInput!) {
    archiveEnterpriseInvoice(data: $data) {
      code
      message
    }
  }
`;

export const DELETE_NOTIFICATION_WORKFLOW = gql`
  mutation DELETE_NOTIFICATION_WORKFLOW(
    $data: deleteNotificationWorkflowInput!
  ) {
    deleteNotificationWorkflow(data: $data)
  }
`;

export const CREATE_DRIVER_ACCOUNT = gql`
  mutation CREATE_DRIVER_ACCOUNT($data: PhoneInput!) {
    createDriverAccount(data: $data)
  }
`;

export const UPDATE_CARD_DETAILS_NATIVE = gql`
  mutation UPDATE_CARD_DETAILS_NATIVE($data: CardDetailsUpdateNativeInput) {
    cardDetailsUpdateNative(data: $data) {
      code
      message
      success
    }
  }
`;

export const DRIVER_SIGN_IN = gql`
  mutation DRIVER_SIGN_IN($data: driverSignInInput!) {
    driverSignin(data: $data) {
      authToken
    }
  }
`;

export const DRIVER_LOGOUT = gql`
  mutation DRIVER_LOGOUT($data: DriverLogoutInput!) {
    driverLogout(data: $data)
  }
`;

export const FLEET_MANAGER_LOGOUT = gql`
  mutation FLEET_MANAGER_LOGOUT($data: FleetManagerLogoutInput!) {
    fleetManagerLogout(data: $data)
  }
`;

export const DEFAULTED_USERS_EXPORT_DATA = gql`
  mutation DEFAULTED_USERS_EXPORT_DATA($data: EmailInput!) {
    getDefaultedUsersExportData(data: $data) {
      readBillingCycle {
        billingCycleId
        cardHolderId
        startingDate
        endingDate
        dueDate
        spendings
        rewards
        outstandingAmount
        status
        invoiceId
        lateFees
        createdAt
        updatedAt
        isSentToQuickbooks
      }
      company {
        firstName
        lastName
        businessName
        businessDesc
        street
        streetDetail
        city
        zip
        ein
        companyType
        businessType
        phoneNumber
        totalAmountDue
        dueDateOfLastUnpaidInvoice
        dateOfLastPayment
      }
      failedInvoiceList {
        startingDate
        endingDate
        spendings
        rewards
        lateFees
        outstandingAmount
        transactionDetails {
          lastFour
          fleetDriverName
          fleetDriverEmail
          transactions {
            date
            merchantName
            merchantState
            merchantPostCd
            merchantCategory
            user
            amount
            status
          }
        }
        billingCycleStatus
        billingCycleInvoiceId
      }
      underwritingData {
        suitableApplication
        kycStatuses
        kybStatuses
        lendFlowStatuses
        underwritingSetting
      }
      creditApplication {
        id
        firstName
        lastName
        phoneNumber
        email
        updatedAt
        statusUpdatedAt
        submittedAt
        ssn
        highRisk
        userProfile {
          id
          firstName
          lastName
          phoneNumber
          email
          isEmailVerified
        }
        business {
          dot
          ein
          mc
          title
          description
          type
          companyType
          cardName
          deliveryType
          addressUsage {
            address {
              id
              streetAddress
              streetDetails
              state
              city
              zip
            }
          }
          document {
            fleetLicense
            fleetLicenseBack
            statements
          }
          fleet {
            fuel
            fleetNum
          }
          plaid {
            institution
          }
        }
        setting {
          id
          creditCycleType
          creditLimit
          updatedCreditLimit
          numberOfCards
          customerType
          additionalLimit
          adminOrderedCards
          platform
        }
        cardHoldersMembership {
          id
          status
        }
      }
      statementOfAccountData {
        amount
        merchant
        description
        date
        accountBalance
      }
    }
  }
`;

export const GET_ADMIN_GRAPH_OF_CARDHOLDERS = gql`
  mutation GET_ADMIN_GRAPH_OF_CARDHOLDERS($data: GraphOfCardholdersInput!) {
    graphOfCardholders(data: $data) {
      ACTIVE
      ACTIVEMORETHANZEROSPENT
      INACTIVE
      BLOCKED
      TOTAL
      creditUsersTransactions
      prepaidUsersTransactions
      creditUsersTransactionsCount
      prepaidUsersTransactionsCount
      totalNumberOfClients
      numberOfActiveClients
    }
  }
`;

export const DRIVER_UPDATE_PHONE = gql`
  mutation DRIVER_UPDATE_PHONE($data: DriverUpdatePhoneInput!) {
    driverUpdatePhone(data: $data) {
      success
      code
      type
      message
      detail
      help
      result {
        id
        email
        phoneNumber
      }
    }
  }
`;

export const UPDATE_SYSTEMS_HEALTH_CHECK = gql`
  mutation UPDATE_SYSTEMS_HEALTH_CHECK {
    updateSystemHealthCheck {
      code
      message
    }
  }
`;

export const SEND_EMAIL_CODE = gql`
  mutation SEND_EMAIL_CODE($data: SendEmailCodeInput!) {
    sendEmailCode(data: $data)
  }
`;

export const DRIVER_UPDATE_EMAIL = gql`
  mutation DRIVER_UPDATE_EMAIL($data: DriverUpdateEmailInput!) {
    driverUpdateEmail(data: $data) {
      success
      code
      type
      message
      detail
      help
      result {
        id
        email
        phoneNumber
      }
    }
  }
`;

export const RESUME_UNDERWRITING = gql`
  mutation RESUME_UNDERWRITING($data: ResumeUnderwritingApplicationInput!) {
    resumeUnderwritingApplication(data: $data) {
      code
      message
    }
  }
`;

export const WAIVE_LATE_FEES = gql`
  mutation WAIVE_LATE_FEES($data: WaiveLateFeesInput!) {
    waiveLateFees(data: $data) {
      code
      message
    }
  }
`;

export const FINAL_BUSINESS_APPROVAL = gql`
  mutation FINAL_BUSINESS_APPROVAL($data: finalBusinessApprovalInput) {
    finalBusinessApproval(data: $data) {
      success
      error
    }
  }
`;

export const UPDATE_AUTO_RELOAD_DETAILS = gql`
  mutation UPDATE_AUTO_RELOAD_DETAILS($data: updateAutoReloadDetailsInput!) {
    updateAutoReloadDetails(data: $data) {
      code
      message
    }
  }
`;

export const CREATE_ACCOUNT_DOCUMENT = gql`
  mutation CREATE_ACCOUNT_DOCUMENT($data: CreateAccountDocumentInput!) {
    createAccountDocument(data: $data) {
      message
      code
    }
  }
`;

export const ASSIGN_DRIVERS_VEHICLES_TO_CARDS = gql`
  mutation ASSIGN_DRIVERS_VEHICLES_TO_CARDS(
    $data: AssignDriversVehiclesToCardsInput
  ) {
    assignDriversVehiclesToCards(data: $data) {
      message
      code
    }
  }
`;

export const DELETE_ACCOUNT_DOCUMENT = gql`
  mutation DELETE_ACCOUNT_DOCUMENT($data: IdAndEmailInput) {
    deleteAccountDocument(data: $data) {
      message
      code
    }
  }
`;

export const PUBLISH_ACCOUNT_DOCUMENT = gql`
  mutation PUBLISH_ACCOUNT_DOCUMENT($data: PublishAccountDocumentInput) {
    publishAccountDocument(data: $data) {
      message
      code
    }
  }
`;

export const CREATE_MANUAL_PAYMENT_HISTORY = gql`
  mutation CREATE_MANUAL_PAYMENT_HISTORY($data: CreatePaymentManuallyInput!) {
    createPaymentHistory(data: $data) {
      message
      code
    }
  }
`;

export const CREATE_REPAYMENT_PLAN = gql`
  mutation CREATE_REPAYMENT_PLAN($data: createRepaymentInput!) {
    createRepayment(data: $data) {
      code
      message
    }
  }
`;

export const STRIPE_CREATE_OFF_SESSION_PAYMENT = gql`
  mutation STRIPE_CREATE_OFF_SESSION_PAYMENT {
    stripeCreateOffSessionPayment {
      code
      message
    }
  }
`;

export const MISSING_DETAILS_UPDATE = gql`
  mutation MISSING_DETAILS_UPDATE($data: MissingDetailsInput!) {
    missingDetailsUpdate(data: $data) {
      code
      message
    }
  }
`;

export const STRIPE_CREATE_MANUAL_PAYMENT = gql`
  mutation STRIPE_CREATE_MANUAL_PAYMENT(
    $data: stripeCreateManualPaymentInput!
  ) {
    stripeCreateManualPayment(data: $data) {
      code
      success
      type
      message
      status
    }
  }
`;

export const STRIPE_CREATE_MANUAL_ACH = gql`
  mutation STRIPE_CREATE_MANUAL_ACH($data: stripeCreateManualACHInput!) {
    stripeCreateManualACH(data: $data) {
      code
      success
      type
      message
      status
    }
  }
`;

export const CONNECT_FLEETIO = gql`
  mutation CONNECT_FLEETIO($data: PersistFleetioTokenInput!) {
    persistFleetioToken(data: $data)
  }
`;

export const REMOVE_FLEETIO = gql`
  mutation REMOVE_FLEETIO {
    removeFleetioConnection {
      message
    }
  }
`;

export const STORE_PERSONA_INQUIRY_ID = gql`
  mutation STORE_PERSONA_INQUIRY_ID($data: StorePersonaInquiryIdInput!) {
    storePersonaInquiryId(data: $data)
  }
`;

export const SAVE_TERMS_OF_SERVICE = gql`
  mutation SAVE_TERMS_OF_SERVICE($data: SaveTermsOfServiceChecksInput!) {
    saveTermsOfServiceChecks(data: $data) {
      message
      code
    }
  }
`;

export const REMOVE_ELD_CONNECTION = gql`
  mutation REMOVE_ELD_CONNECTION($data: EldRemoveInput!) {
    eldRemove(data: $data) {
      message
    }
  }
`;

export const DATA_ANALYSIS_USERS = gql`
  mutation DATA_ANALYSIS_USERS($data: DataAnalysisUsersInput) {
    queryUsersDataAnalysis(data: $data) {
      code
      message
      users {
        fullName
        approvedDate
        accountStatus
        businessName
        outstandingAmount
        email
        phoneNumber
        cardHolderStatus
        highRisk
        failedBillingCycle
        cardsUnderAccount
        atleastOneCardActive
        daysPassedSinceLastSpend
        customerSupportAdminId
        customerType
        totalNumberOfCards
        numberOfActiveCards
        totalSpend
        totalWalletAmount
        customerTier
        lastAuthorizationDate
      }
    }
  }
`;

export const UPDATE_CUSTOMER_TYPE_UPGRADE = gql`
  mutation UPDATE_CUSTOMER_TYPE_UPGRADE(
    $data: updateCustomerTypeUpgradeInput!
  ) {
    updateCustomerTypeUpgrade(data: $data) {
      code
      message
    }
  }
`;

export const ADMIN_DOWNLOAD_METRICS_REPORT = gql`
  mutation ADMIN_DOWNLOAD_METRICS_REPORT($data: MetricTypeInput) {
    adminDownloadMetricsReport(data: $data) {
      contents
    }
  }
`;

export const UPDATE_SMS_TEMPLATE_FOR_PROSPECTIVE_LEADS = gql`
  mutation UPDATE_SMS_TEMPLATE_FOR_PROSPECTIVE_LEADS(
    $data: UpdateCommunicationTemplateForProspectiveLeads
  ) {
    updateSmsTemplateForProspectiveLeads(data: $data) {
      message
    }
  }
`;

export const UPLOAD_PROSPECTIVE_LEADS_COMMUNICATION_FILE = gql`
  mutation UPLOAD_PROSPECTIVE_LEADS_COMMUNICATION_FILE(
    $data: CreateCommunicationLeadFile
  ) {
    uploadProspectiveLeadsCommunicationFile(data: $data) {
      code
      message
    }
  }
`;

export const CREATE_PROMOTION = gql`
  mutation CREATE_PROMOTION($data: CreatePromotionInput) {
    createPromotion(data: $data) {
      message
      code
    }
  }
`;

export const UPDATE_USER_ENTERPRISE_SETTINGS = gql`
  mutation UPDATE_USER_ENTERPRISE_SETTINGS(
    $data: UpdateEnterpriseSettingsInput!
  ) {
    updateUserEnterpriseSettings(data: $data) {
      code
      message
    }
  }
`;

export const DEACTIVATE_PROMOTION = gql`
  mutation DEACTIVATE_PROMOTION($data: IdInputList) {
    deactivatePromotion(data: $data) {
      code
      message
    }
  }
`;

export const VERIFY_DEVICE_FOR_CURRENT_USER = gql`
  mutation VERIFY_DEVICE_FOR_CURRENT_USER(
    $data: verifyDeviceForCurrentUserInput
  ) {
    verifyDeviceForCurrentUser(data: $data) {
      deviceToken
    }
  }
`;

export const READ_ADMIN_EMAIL_LOGS = gql`
  mutation READ_ADMIN_EMAIL_LOGS($data: readEmailHistoryInput!) {
    readEmailHistory(data: $data) {
      code
      message
      totalCount
      emailHistory {
        email
        status
        messageId
        result
        emailType
        variables
        createdAt
        updatedAt
      }
    }
  }
`;

export const START_HIGHNOTE_SESSION = gql`
  mutation START_HIGHNOTE_SESSION($data: startHighnoteSessionInput!) {
    startHighnoteSession(data: $data) {
      code
      message
      success
    }
  }
`;

export const END_HIGHNOTE_SESSION = gql`
  mutation END_HIGHNOTE_SESSION($data: endHighnoteSessionInput!) {
    endHighnoteSession(data: $data) {
      code
      message
      success
    }
  }
`;

export const UPLOAD_HIGHNOTE_DOCUMENT = gql`
  mutation UPLOAD_HIGHNOTE_DOCUMENT($data: uploadHighnoteDocumentInput!) {
    uploadHighnoteDocument(data: $data) {
      code
      message
      success
    }
  }
`;

export const SYNC_ELD_DEVICE = gql`
  mutation SYNC_ELD_DEVICE($data: SyncEldDeviceInput!) {
    eldSyncDevice(data: $data) {
      result
    }
  }
`;

export const AUTHENTICATE_EJ_WARD = gql`
  mutation AUTHENTICATE_EJ_WARD($data: ejWardAuthenticateInput!) {
    authenticateEJWard(data: $data) {
      code
      message
    }
  }
`;

export const REMOVE_EJ_WARD = gql`
  mutation REMOVE_EJ_WARD {
    removeEjWard {
      code
      message
    }
  }
`;

export const CREATE_OR_UPDATE_ACCOUNT_CONTROL_SETTINGS = gql`
  mutation CREATE_OR_UPDATE_ACCOUNT_CONTROL_SETTINGS(
    $data: CreateOrUpdateAccountControlSettings!
  ) {
    createOrUpdateAccountControlSettings(data: $data) {
      code
      message
    }
  }
`;

export const UPDATE_DYNAMIC_PURCHASE_CONTROLS = gql`
  mutation UPDATE_DYNAMIC_PURCHASE_CONTROLS(
    $data: UpdateDynamicPurchaseControlsInput!
  ) {
    updateDynamicPurchaseControls(data: $data) {
      code
      message
    }
  }
`;

export const CREATE_AND_UPDATE_DELIVERY_ADDRESS = gql`
  mutation CREATE_AND_UPDATE_DELIVERY_ADDRESS(
    $data: CreateAndUpdateDeliveryAddressInput!
  ) {
    createAndUpdateDeliveryAddress(data: $data) {
      code
      message
    }
  }
`;

export const REMOVE_DELIVERY_ADDRESS = gql`
  mutation REMOVE_DELIVERY_ADDRESS($data: RemoveDeliveryAddressInput!) {
    removeDeliveryAddress(data: $data) {
      code
      message
    }
  }
`;
