/** @format */

import { useCreditToPrepaid } from "@roadflex/react-hooks";
import { usePlatformDetect } from "@roadflex/react-hooks-www";
import classNames from "classnames";
import getConfig from "next/config";
import { Button, ButtonLink, ButtonSize, ButtonVariant } from "../../buttons";
import { OnboardBannerComponent } from "../../onboard/common-banner-component";
import { Toast } from "../../toast-message/toast";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_WWW_URL } = publicRuntimeConfig;

type NoAccessUIProps = {
  isPrepaidEligible?: boolean;
};

export const NoAccessUI = ({ isPrepaidEligible }: NoAccessUIProps) => {
  const { onCreditToPrepaidFn, loading, error } = useCreditToPrepaid();
  const { isMobile } = usePlatformDetect();
  return (
    <div
      className={classNames(
        isMobile() ? "flex-col" : "flex-row",
        "flex w-full h-full min-h-screen",
      )}
    >
      <OnboardBannerComponent header="Application Status" subText="" />
      <div className="flex flex-col w-full px-4 py-8 md:p-16">
        {!isPrepaidEligible && (
          <div className="">
            <div className="mb-6">
              If you recently submitted your application, you will hear back
              from us in 3-5 business days. Prepaid applications are often
              approved in 1 day.
            </div>
            <div className="mb-6">Check your email for updates.</div>
            <div className="mb-12">
              If you received an email and recently enrolled in the prepaid card
              program, refresh this page after 15 minutes.
            </div>
            <div className="mb-12">
              <div className="mb-2">Any questions? </div>
              <div className="mb-4 italic">
                Call (877) 870-7440 or email{" "}
                <a
                  className="text-brown-800"
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:hello@roadflex.com"
                >
                  hello@roadflex.com
                </a>
                .
              </div>
              <ButtonLink
                href={NEXT_PUBLIC_WWW_URL}
                variant={ButtonVariant.Orange}
                className="w-full"
              >
                Go to roadflex.com
              </ButtonLink>
            </div>
          </div>
        )}
        {isPrepaidEligible && (
          <div className="">
            <div className="mb-12 text-xl font-bold text-center">
              Your application has been reviewed.
            </div>
            <div className="mb-4">
              You are eligible to be part of the RoadFlex Prepaid Card Program.
              Click on this button to confirm.
            </div>
            <div className="flex justify-center mb-5">
              <Button
                variant={ButtonVariant.PrimaryOutline}
                size={ButtonSize.Normal}
                disabled={loading}
                onClick={async () => {
                  try {
                    await onCreditToPrepaidFn({
                      variables: {},
                    });
                    Toast({
                      type: "success",
                      message:
                        "You are now enrolled in the prepaid program. Refresh the page in 5 minutes.",
                    });
                  } catch (err: unknown) {
                    if (err instanceof Error) {
                      Toast({ type: "error", message: err.message });
                    } else {
                      Toast({
                        type: "error",
                        message:
                          "An error has occurred enrolling you into the prepaid program",
                      });
                    }
                  }
                }}
              >
                Confirm Prepaid Enrollment
              </Button>
            </div>

            <div className="mt-12">
              <div className="mb-2">Any questions? </div>
              <div className="mb-4 italic">
                Call (877) 870-7440 or email{" "}
                <a
                  className="text-brown-800"
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:hello@roadflex.com"
                >
                  hello@roadflex.com
                </a>
                .
              </div>
              <ButtonLink
                href={NEXT_PUBLIC_WWW_URL}
                variant={ButtonVariant.Orange}
                className="w-full"
              >
                Go to roadflex.com
              </ButtonLink>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
