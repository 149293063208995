/** @format */

import {
  DocumentMagnifyingGlassIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import { BUSINESS_TYPE, COMPANY_TYPE } from "@roadflex/constants";
import { usePlatformDetect } from "@roadflex/react-hooks-www";
import { BusinessInformationValues, BusinessType } from "@roadflex/types";
import { einFormatter } from "@roadflex/web-lib";
import classNames from "classnames";
import { FormikProps } from "formik";
import getConfig from "next/config";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import { Select } from "../dropdowns";
import { TextAreaInput, TextInput } from "../inputs";
import { BusinessInformationConfirmationModal } from "../modals";
import { OnboardBannerComponent } from "./common-banner-component";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;

interface BusinessInformationUIProps
  extends FormikProps<BusinessInformationValues> {
  isSubmittingOnboardStatus: boolean;
  showBusinessInformationConfirmationModal: boolean;
  setShowBusinessInformationConfirmationModal: (
    showBusinessInformationConfirmationModal: boolean,
  ) => void;
  business: BusinessType;
}

const BusinessInformationUI = ({
  values,
  handleChange,
  handleSubmit,
  isSubmitting,
  isSubmittingOnboardStatus,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  validateForm,
  setFieldTouched,
  dirty,
  showBusinessInformationConfirmationModal,
  setShowBusinessInformationConfirmationModal,
  business,
}: BusinessInformationUIProps) => {
  const handleSetAllTouched = () => {
    Object.keys(values).forEach((field) => {
      setFieldTouched(field, true);
    });
  };
  const { isMobile } = usePlatformDetect();
  return (
    <div
      className={classNames(
        isMobile() ? "flex-col" : "flex-row",
        "flex w-full h-full min-h-screen",
      )}
    >
      <OnboardBannerComponent
        header="Tell us about your business (1 of 2)"
        subText="We'll use this information to verify your company's details."
      />

      <div className="flex flex-col w-full text-center rounded-md">
        <div className="flex items-center min-h-[245px]">
          <div className="flex flex-col w-full px-4 py-8 md:p-16">
            {values.customerType?.split("_")[1] === "ENTERPRISE" ? null : (
              <div className="relative w-full mb-4">
                <Select
                  label="Application Type"
                  subLabel="Prepaid will be approved faster."
                  value={values.customerType}
                  name="customerType"
                  options={[
                    {
                      label: "Prepaid",
                      value: "PREPAID",
                      disabled: false,
                    },
                  ]}
                  optionLabel="label"
                  optionValue="value"
                  onChange={(e) => setFieldValue("customerType", e.value)}
                  onBlur={() => setFieldTouched("customerType", true)}
                  placeholder="-- Select an option --"
                  touched={touched.customerType}
                  error={touched.customerType && Boolean(errors.customerType)}
                  errorMessage={errors.customerType}
                  required
                  variant="responsive"
                  labelClass={`text-left`}
                ></Select>
              </div>
            )}
            <div className="">
              <TextInput
                label="Legal Company Name"
                subLabel="Provide the exact name as it appears in your incorporation documents (147C or SS-4)."
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="title"
                error={touched.title && Boolean(errors.title)}
                errorMessage={errors.title}
                touched={touched.title}
                variant="responsive"
                informationToolTip="Full legal business name used to register the business is required. You can find it on the IRS forms 147C or SS-4. Any typos, shortened names, or wrong casing of business name can lead to lengthened review times."
                required
                labelClass={`text-left`}
              ></TextInput>
            </div>
            <div className="mt-2">
              <TextInput
                label="Years of Operation"
                name="yearsOfOperation"
                type="number"
                onWheel={(e) => {
                  // Prevent the input value change
                  e?.currentTarget?.blur();

                  // Prevent the page/container scrolling
                  e.stopPropagation();

                  // Refocus immediately, on the next tick (after the current function is done)
                  setTimeout(() => {
                    e?.currentTarget?.focus();
                  }, 0);
                }}
                value={values.yearsOfOperation}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="yearsOfOperation"
                error={
                  touched.yearsOfOperation && Boolean(errors.yearsOfOperation)
                }
                errorMessage={errors.yearsOfOperation}
                touched={touched.yearsOfOperation}
                variant="responsive"
                required
              ></TextInput>
            </div>
            <div className="mt-2">
              <TextInput
                label="Employer Identification Number (EIN - 9 digit)"
                name="ein"
                value={values.ein}
                onChange={(event) =>
                  setFieldValue("ein", einFormatter(event?.target?.value))
                }
                onBlur={handleBlur}
                error={touched.ein && Boolean(errors.ein)}
                errorMessage={errors.ein}
                touched={touched.ein}
                variant="responsive"
                informationToolTip="Your Employer Identification Number (EIN) also known as a Taxpayer Identification Number or Tax ID is used to verify your business. It can be found in your EIN confirmation letter, your previously filed tax returns, and other tax notices from the IRS."
                required
              ></TextInput>
            </div>
            <div className="mt-2">
              <Select
                label="Type of Business"
                value={values.type}
                name="type"
                options={[
                  {
                    label: "-- Select an option --",
                    value: "",
                    disabled: true,
                  },
                ].concat(
                  Object.keys(BUSINESS_TYPE).map((key) => {
                    return {
                      label: BUSINESS_TYPE[key],
                      value: key,
                      disabled: false,
                    };
                  }),
                )}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => setFieldValue("type", e.value)}
                onBlur={() => setFieldTouched("type", true)}
                placeholder="-- Select an option --"
                touched={touched.type}
                error={touched.type && Boolean(errors.type)}
                errorMessage={errors.type}
                required
                variant="responsive"
                labelClass={`text-left`}
              ></Select>
            </div>
            <div className="mt-2">
              <Select
                label="Type of Industry"
                value={values.companyType}
                name="companyType"
                options={[
                  {
                    label: "-- Select an option --",
                    value: "",
                    disabled: true,
                  },
                ].concat(
                  Object.keys(COMPANY_TYPE).map((key) => {
                    return {
                      label: COMPANY_TYPE[key],
                      value: key,
                      disabled: false,
                    };
                  }),
                )}
                optionLabel="label"
                optionValue="value"
                onChange={(e) => setFieldValue("companyType", e.value)}
                onBlur={() => setFieldTouched("companyType", true)}
                placeholder="-- Select an option --"
                touched={touched.companyType}
                error={touched.companyType && Boolean(errors.companyType)}
                errorMessage={errors.companyType}
                required
                variant="responsive"
                labelClass={`text-left`}
              ></Select>
            </div>
            {values.uploadedIRSLetter?.length > 0 &&
            !values.irsLetterReupload ? (
              <div className="w-full mt-2 mb-2 text-sm md:text-base">
                <div>
                  Uploaded IRS Letter 147C / IRS SS-4 Confirmation Letter :{" "}
                  <a
                    href={`${NEXT_PUBLIC_API_URL}/api/files/${values.uploadedIRSLetter}`}
                    rel="noreferrer"
                    target="_blank"
                    className="underline underline-offset-1"
                  >
                    IRS Letter
                  </a>
                </div>
                <div className="flex items-center justify-center">
                  If you want to upload a new file, click{" "}
                  <Button
                    type={ButtonType.Button}
                    variant={ButtonVariant.Transparent}
                    size={ButtonSize.Small}
                    className="!p-0 ml-1 underline underline-offset-1"
                    onClick={() => {
                      setFieldValue("irsLetterReupload", true);
                    }}
                  >
                    <span className="text-sm md:text-base">here</span>
                  </Button>
                </div>
              </div>
            ) : (
              <div className="relative w-full mt-2">
                <div className="mb-1 text-sm text-left whitespace-normal md:mb-2 md:text-base">
                  Upload the IRS Letter 147C or SS-4 Confirmation Letter*
                </div>
                <div>
                  <div className="flex items-center justify-center w-full mx-auto text-gray-500 bg-white rounded-lg cursor-pointer">
                    <label className="flex flex-col w-full h-32 cursor-pointer">
                      <div className="flex flex-col items-center justify-center pt-7">
                        <DocumentMagnifyingGlassIcon
                          className="w-10 h-10 text-gray-500"
                          aria-hidden="true"
                        />
                        <p className="pt-1 text-sm tracking-wider group-hover:text-gray-600">
                          Browse
                        </p>
                        <div className="w-full px-2 overflow-y-hidden max-h-6">
                          {values.irsLetter instanceof File &&
                            values.irsLetter?.name}
                        </div>
                      </div>
                      <input
                        type="file"
                        id="irsLetter"
                        onChange={(event) => {
                          setFieldValue(
                            "irsLetter",
                            event?.target?.files ? event?.target?.files[0] : "",
                          );
                        }}
                        className="opacity-0"
                        onBlur={handleBlur}
                        accept=".pdf"
                      />
                    </label>
                  </div>
                </div>

                {touched.irsLetter && (
                  <div className="mt-1 text-sm text-red-500">
                    {errors.irsLetter}
                  </div>
                )}
                <div className="flex items-center justify-between mt-1 text-sm">
                  <span>Accepted file type: .pdf only (Maximum size: 3MB)</span>{" "}
                  <span className="flex items-center ml-4 ">
                    <i className="mr-1 fa fa-lock"></i> secure
                  </span>{" "}
                </div>
              </div>
            )}
            <div className="relative w-full mt-2">
              <TextInput
                label="Business Name on Physical Cards"
                subLabel="This will be the name that appears on your cards."
                name="cardName"
                value={values.cardName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.cardName && Boolean(errors.cardName)}
                errorMessage={errors.cardName}
                touched={touched.cardName}
                variant="responsive"
                required
                sublabelClassName={`text-left`}
              ></TextInput>
            </div>
            <div className="relative w-full mt-2">
              <TextAreaInput
                label="What does your business do? (Optional)"
                name="description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.description && Boolean(errors.description)}
                errorMessage={errors.description}
                touched={touched.description}
                rows={4}
                maxLength={200}
                variant="responsive"
                labelClassName={`text-left`}
              ></TextAreaInput>
            </div>
            <div className="mt-2 text-xs md:text-sm">*Required Fields</div>

            <div className="mt-6 text-right">
              <Button
                type={ButtonType.Button}
                onClick={() =>
                  validateForm().then(() => {
                    handleSetAllTouched();
                    if (Object.keys(errors).length === 0) {
                      if (values.customerType) {
                        if (
                          values.customerType?.split("_")[1] === "ENTERPRISE" ||
                          business?.document
                        ) {
                          handleSubmit();
                        } else {
                          setShowBusinessInformationConfirmationModal(true);
                        }
                      }
                    }
                  })
                }
                variant={ButtonVariant.Orange}
                size={ButtonSize.Normal}
                className="w-full"
                disabled={isSubmitting || isSubmittingOnboardStatus}
                loading={isSubmitting || isSubmittingOnboardStatus}
              >
                <LockClosedIcon className="w-4 h-4 mr-1"></LockClosedIcon>
                {dirty ? `Save` : `Next`}
              </Button>
            </div>
            <div className="flex items-center justify-center mt-1 text-xs md:text-sm">
              <ShieldCheckIcon className="w-4 h-4 mr-1"></ShieldCheckIcon>
              We securely store and encrypt your personal information
            </div>
          </div>
        </div>
      </div>

      <BusinessInformationConfirmationModal
        confirm={handleSubmit}
        submitting={isSubmitting || isSubmittingOnboardStatus}
        showBusinessInformationConfirmationModal={
          showBusinessInformationConfirmationModal
        }
        setShowBusinessInformationConfirmationModal={
          setShowBusinessInformationConfirmationModal
        }
        customerType={values.customerType}
      />
    </div>
  );
};

export default BusinessInformationUI;
