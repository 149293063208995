/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ENUM_MAIL_TYPE } from "@prisma/client";
import { EmailTemplateDetails } from "@roadflex/types";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import AdminModalLoader from "../../loader/admin-modal-loader";

type AddEditMailTypeModalProps = {
  editMailTypePopup: boolean;
  addedTemplates: EmailTemplateDetails[];
  setEditMailTypePopup: (editMailTypePopup: boolean) => void;
  createUpdateMailTypeValues: {
    mailType: string;
    templateName: string;
  };
  createUpdateMailTypeSetFieldValue: (field: string, value: string) => void;
  createUpdateMailTypeHandleSubmit: () => void;
  createUpdateMailTypesIsSubmitting: boolean;
};

export const AddEditMailTypeModal = ({
  editMailTypePopup,
  setEditMailTypePopup,
  addedTemplates,
  createUpdateMailTypeValues,
  createUpdateMailTypeSetFieldValue,
  createUpdateMailTypeHandleSubmit,
  createUpdateMailTypesIsSubmitting,
}: AddEditMailTypeModalProps) => {
  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-end">
        <Button
          type={ButtonType.Button}
          variant={ButtonVariant.Primary}
          size={ButtonSize.AppSize}
          disabled={createUpdateMailTypesIsSubmitting}
          className=""
          onClick={() => createUpdateMailTypeHandleSubmit()}
        >
          Save
        </Button>
        <Button
          type={ButtonType.Button}
          variant={ButtonVariant.SecondaryOutline}
          size={ButtonSize.AppSize}
          disabled={createUpdateMailTypesIsSubmitting}
          className=""
          onClick={() => setEditMailTypePopup(false)}
        >
          Close
        </Button>
      </div>
    );
  };
  return (
    <Dialog
      header={<div className="text-base">{`Add/Edit Mail Template`}</div>}
      style={{ minWidth: "60vw", maxWidth: "80vw", fontFamily: "Inter" }}
      visible={editMailTypePopup}
      onHide={() => setEditMailTypePopup(false)}
      closeOnEscape={true}
      closable={false}
      footer={renderFooter}
    >
      <AdminModalLoader showLoading={createUpdateMailTypesIsSubmitting} />
      <form className="w-full mt-3 sm:mt-5">
        <div className="relative w-full mt-4">
          <div className="mb-1 text-sm whitespace-nowrap">Mail Type</div>
          <select
            onChange={(e) =>
              createUpdateMailTypeSetFieldValue("mailType", e.target.value)
            }
            name="mailType"
            value={createUpdateMailTypeValues.mailType}
            className={classNames(
              "block w-full disabled:opacity-50 pl-3 pr-8 py-2 border rounded focus:outline-none focus:ring-0 focus:shadow-none",
            )}
          >
            <option value="" disabled hidden>
              Select Mail Type
            </option>
            {(
              Object.keys(ENUM_MAIL_TYPE) as Array<keyof typeof ENUM_MAIL_TYPE>
            ).map((mailType, idx) => (
              <option value={mailType} key={idx}>
                {mailType}
              </option>
            ))}
          </select>
        </div>
        <div className="relative w-full mt-4">
          <div className="mb-1 text-sm whitespace-nowrap">Template Name</div>
          <select
            onChange={(e) =>
              createUpdateMailTypeSetFieldValue("templateName", e.target.value)
            }
            name="templateName"
            value={createUpdateMailTypeValues.templateName}
            className={classNames(
              "block w-full disabled:opacity-50 pl-3 pr-8 py-2 border rounded focus:outline-none focus:ring-0 focus:shadow-none",
            )}
          >
            <option value="" disabled hidden>
              Select Template
            </option>
            {addedTemplates.length > 0 &&
              addedTemplates?.map(
                (item: { templateName: string }, idx: number) => (
                  <option value={item.templateName} key={idx}>
                    {item.templateName}
                  </option>
                ),
              )}
          </select>
        </div>
      </form>
    </Dialog>
  );
};
