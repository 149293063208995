/* eslint-disable dot-notation */
/** @format */
import { useMutation } from "@apollo/client";
import {
  DocumentMagnifyingGlassIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import { IMPORT_TRANSACTIONS } from "@roadflex/graphql";
import { Parser } from "csv-parse";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import * as Yup from "yup";
import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
} from "../../../buttons";
import ExportImportModalLoader from "../../../loader/export-import-modal-loader";
import { Toast } from "../../../toast-message/toast";

// Validation schema for a single transaction
const transactionValidationSchema = Yup.object().shape({
  date: Yup.date().required("Date is required"),
  merchantName: Yup.string().required("Merchant name is required"),
  location: Yup.string().required("Location is required"),
  fuelQuantity: Yup.number()
    .positive("Fuel quantity must be positive")
    .required("Fuel quantity is required"),
  transactionAmount: Yup.number()
    .positive("Amount must be positive")
    .required("Amount is required"),
});

// Validation schema for the file
const transactionFileValidationSchema = Yup.object().shape({
  transactionsFile: Yup.mixed()
    .required("Please select a file")
    .test("fileSize", "File size is too large", (value) => {
      if (!value) return true;
      return (value as File).size <= 3 * 1024 * 1024; // 3MB
    })
    .test("fileType", "Unsupported file type", (value) => {
      if (!value) return true;
      return (
        (value as File).type === "text/csv" ||
        (value as File).name.endsWith(".csv")
      );
    }),
});

export type UploadTransactionsFileProps = {
  uploadTransactionsFilePopup: boolean;
  setUploadTransactionsFilePopup: (
    uploadTransactionsFilePopup: boolean,
  ) => void;
  refetchTransactions: () => void;
};

interface CSVRow {
  date: string;
  merchantName: string;
  location: string;
  fuelQuantity: number;
  transactionAmount: number;
  extraInfo: Record<string, string>;
  error?: string;
}

export default function UploadTransactionsFile({
  uploadTransactionsFilePopup,
  setUploadTransactionsFilePopup,
  refetchTransactions,
}: UploadTransactionsFileProps) {
  const [importTransactionsFn] = useMutation(IMPORT_TRANSACTIONS);

  const columnMapping: Record<string, keyof CSVRow> = {
    Date: "date",
    Merchant: "merchantName",
    Location: "location",
    "Fuel Quantity": "fuelQuantity",
    Amount: "transactionAmount",
    Errors: "error",
  };

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);
  const [csvData, setCSVData] = useState<CSVRow[] | null>(null);
  const [isCsvValidationError, setCsvValidationError] = useState(false);

  const createTransactions = async () => {
    if (csvData && csvData?.length > 0) {
      const response = await importTransactionsFn({
        variables: {
          data: {
            transactions: csvData.map((transactionObj) => {
              const [month, day, year] = transactionObj.date.split("/");
              const utcDate = new Date(
                Date.UTC(
                  Number(year),
                  Number(month) - 1,
                  Number(day),
                  8, // 8 AM UTC = 12 AM PST
                  0,
                  0,
                  0,
                ),
              );

              const newTransactionObj = {
                ...transactionObj,
                date: utcDate.toISOString(),
                fuelQuantity: Number(transactionObj.fuelQuantity),
                transactionAmount:
                  Number(transactionObj.transactionAmount) * 100,
              };
              delete newTransactionObj.error;
              return newTransactionObj;
            }),
          },
        },
      });

      if (response?.data?.importTransactions?.code === "200") {
        Toast({
          type: "success",
          message:
            response?.data?.importTransactions?.message ||
            "Transactions uploaded successfully",
        });
        setShowLoading(false);
        setShowComplete(true);
      }
      refetchTransactions();
    } else {
      throw new Error("No data found");
    }
  };

  type FormikValues = {
    transactionsFile: File | null;
  };

  const initialValues: FormikValues = { transactionsFile: null };

  const parseAndValidateFile = (file: File | null) => {
    setCsvValidationError(false);
    if (!file) {
      return false;
    }

    const parser = new Parser({
      delimiter: ",",
      columns: true,
      skip_empty_lines: true,
    });

    const parsedRows: CSVRow[] = [];

    parser.on("data", async (row: Record<string, string>) => {
      // Skip if all fields are empty or just whitespace
      const isEmptyRow = Object.values(row).every(
        (value) => !value || value.toString().trim() === "",
      );

      if (isEmptyRow) {
        return;
      }

      const extraInfo: Record<string, string> = {};

      Object.entries(row).forEach(([key, value]) => {
        if (key !== "Errors") {
          extraInfo[key] = value?.trim() || "";
        }
      });

      const mappedRow: CSVRow = {
        date: row["Date"]?.trim() || "",
        merchantName: row["Merchant"]?.trim() || "",
        location: row["Location"]?.trim() || "",
        fuelQuantity: Number(row["Fuel Quantity"]?.replace(/,/g, "")),
        transactionAmount: Number(row["Amount"]?.replace(/[$,]/g, "")),
        extraInfo,
      };

      try {
        await transactionValidationSchema.validate(mappedRow, {
          abortEarly: false,
        });
        mappedRow.error = "";
        parsedRows.push(mappedRow);
      } catch (validationError) {
        setCsvValidationError(true);
        const yupValidationError = validationError as Yup.ValidationError;
        const uniqueErrorsSet = new Set(yupValidationError.errors);
        const uniqueErrorsArray = [...uniqueErrorsSet];
        mappedRow.error = uniqueErrorsArray.join(", ");
        parsedRows.push(mappedRow);
      }
    });

    parser.on("end", () => {
      setCSVData(parsedRows);
    });

    parser.on("error", (err) => {
      console.error(err.message);
      setShowLoading(false);
    });

    const reader = new FileReader();
    reader.onload = (e) => {
      const csvText = e.target?.result as string;
      parser.write(csvText);
      parser.end();
    };

    reader.readAsText(file);
    return true;
  };

  const generateCSVString = (data: CSVRow[]): string => {
    const lines: string[] = [];
    const header = Object.keys(columnMapping)
      .map((columnName) => `"${columnName}"`)
      .join(",");
    lines.push(header);

    for (const row of data) {
      const columnValues = Object.values(columnMapping).map(
        (columnName) => `"${row[columnName]}"`,
      );
      lines.push(columnValues.join(","));
    }

    return lines.join("\n");
  };

  const downloadErrorFile = () => {
    if (csvData) {
      const csvString = generateCSVString(csvData);
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "transaction_file_errors.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(url);
    }
  };

  const downloadTemplateFile = () => {
    const header = Object.keys(columnMapping)
      .filter((columnName) => columnName !== "Errors")
      .map((columnName) => `"${columnName}"`)
      .join(",");

    const blob = new Blob([header], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "transaction_file.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  };

  const {
    handleSubmit,
    handleBlur,
    values,
    touched,
    isSubmitting,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: transactionFileValidationSchema,
    onSubmit: async (value: FormikValues) => {
      try {
        setShowLoading(true);
        // parseAndValidateFile(value.transactionsFile);
        await createTransactions();
      } catch (err: unknown) {
        if (err instanceof Error) {
          Toast({ type: "error", message: err.message });
        } else {
          Toast({ type: "error", message: "Something went wrong" });
        }
        setShowLoading(false);
      }
    },
  });

  const header = (
    <div className="flex flex-row items-center justify-between text-lg font-medium leading-6 text-center text-gray-900">
      <div className="flex flex-row">
        <div>Upload Transactions File</div>
      </div>
      {!showLoading && !showComplete && (
        <Button
          type={ButtonType.Button}
          size={ButtonSize.AppSize}
          onClick={downloadTemplateFile}
          variant={ButtonVariant.GrayOutline}
          className="inline-flex"
        >
          Download Template
        </Button>
      )}
    </div>
  );

  const footer = (
    <div className="flex flex-row justify-end">
      {!showLoading && (
        <Button
          variant={ButtonVariant.GrayOutline}
          size={ButtonSize.AppSize}
          type={ButtonType.Button}
          onClick={() => {
            resetForm();
            setUploadTransactionsFilePopup(false);
          }}
        >
          Close
        </Button>
      )}

      {!showLoading && !showComplete && (
        <Button
          type={ButtonType.Button}
          variant={ButtonVariant.Black}
          size={ButtonSize.AppSize}
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={() => {
            if (isCsvValidationError) {
              downloadErrorFile();
            } else {
              handleSubmit();
            }
          }}
          className="inline-flex"
        >
          {isCsvValidationError ? "Download Error Log" : "Import"}
        </Button>
      )}
    </div>
  );

  return (
    <Dialog
      header={<div className="text-base">{header}</div>}
      visible={uploadTransactionsFilePopup}
      style={{ fontFamily: "Inter" }}
      className="w-[95%] sm:w-3/4 lg:max-w-[750px] md:max-w-[500px]"
      footer={footer}
      closable={false}
      onHide={() => setUploadTransactionsFilePopup(false)}
    >
      <div>
        {showLoading || showComplete ? (
          <ExportImportModalLoader
            showLoading={showLoading}
            showComplete={showComplete}
            isImport={true}
          />
        ) : (
          <div className="relative w-full">
            <div className="mb-1 text-sm whitespace-normal">
              Upload a CSV file with the following columns: Date (in PST with
              format: MM/DD/YYYY), Merchant, Location, Fuel Quantity, and
              Amount.
            </div>

            <div className="flex items-center justify-center w-full mx-auto text-gray-500 bg-gray-200 rounded-lg cursor-pointer">
              <label className="flex flex-col w-full h-32 cursor-pointer">
                <div className="flex flex-col items-center justify-center pt-7">
                  <DocumentMagnifyingGlassIcon
                    className="w-10 h-10 text-gray-500"
                    aria-hidden="true"
                  />
                  <p className="pt-1 text-sm tracking-wider group-hover:text-gray-600">
                    Browse
                  </p>
                  <div className="w-full px-2 overflow-y-hidden text-center max-h-6">
                    {values.transactionsFile instanceof File &&
                      values.transactionsFile?.name}
                  </div>
                </div>
                <input
                  type="file"
                  id="transactionsFile"
                  onChange={(event) => {
                    if (event?.target?.files) {
                      setFieldValue("transactionsFile", event.target.files[0]);
                      parseAndValidateFile(event.target.files[0]);
                    }
                  }}
                  onClick={(event) => {
                    const input = event.target as HTMLInputElement;
                    input.value = "";
                    setFieldValue("transactionsFile", null);
                  }}
                  className="opacity-0"
                  onBlur={handleBlur}
                  accept=".csv"
                />
              </label>
            </div>

            {touched.transactionsFile && (
              <div>
                <div className="mt-1 text-sm text-red-500">
                  {String(errors?.transactionsFile || "")}
                </div>
                {isCsvValidationError ? (
                  <div className="mt-1 text-sm text-red-500">
                    There are some errors in the Transactions file
                  </div>
                ) : null}
              </div>
            )}
            <div className="flex items-center justify-between mt-1 text-sm text-gray-500">
              <span>Accepted file type: csv only (Maximum size: 3MB)</span>{" "}
              <span className="flex items-center ml-4">
                <LockClosedIcon className="w-4 h-4 mr-1" />
                secure
              </span>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
}
