/** @format */

import { useMutation } from "@apollo/client";
import { ENUM_ADDRESS_TYPE, ENUM_JOB_TITLE } from "@prisma/client";
import { onboardOwnersAndExecutivesSchema } from "@roadflex/constants";
import {
  READ_OWNERS_AND_EXECUTIVES,
  REMOVE_OWNER_AND_EXECUTIVE,
} from "@roadflex/graphql";
import {
  useCreateAndUpdateOwners,
  useReadOwnersAndExecutives,
} from "@roadflex/react-hooks";
import { OwnerAndExecutiveType, UserOnboardType } from "@roadflex/types";
import { phoneFormatter } from "@roadflex/utils";
import getConfig from "next/config";
import { useRef, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { Toast } from "../toast-message/toast";
import BeneficialOwnersUI from "./beneficial-owners.ui";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_GOOGLE_MAP_KEY } = publicRuntimeConfig;
interface BeneficialOwnersProps {
  onJumpToStep: (step: number) => void;
  nextStep: (jumpStepBy?: number) => void;
  onPreviousStep: () => void;
  isSubmittingOnboardStatus: boolean;
  ownersAndExecutives: OwnerAndExecutiveType[];
}

const BeneficialOwners = ({
  nextStep,
  isSubmittingOnboardStatus,
  ownersAndExecutives,
  onJumpToStep,
  onPreviousStep,
}: BeneficialOwnersProps) => {
  const [showInputs, setShowInputs] = useState(false);
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    handleBlur,
    errors,
    setValues,
    resetForm,
    touched,
    ...rest
  } = useCreateAndUpdateOwners({
    initialValues: {
      id: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      ssn: "",
      dateOfBirth: "",
      executiveJobTitle: "OWNER",
      email: "",
      homeAddress: {
        city: "",
        state: "",
        streetAddress: "",
        streetDetails: "",
        type: ENUM_ADDRESS_TYPE.HOME,
        zip: "",
        id: "",
      },
    },
    validationSchema: onboardOwnersAndExecutivesSchema,
    onSubmit(res, err) {
      if (!err) {
        if (res?.code === "200") {
          Toast({
            type: "success",
            message: values.id
              ? "Business owner updated succesfully"
              : "Business owner added succesfully",
          });
        } else {
          Toast({
            type: "error",
            message: res?.message || "",
          });
        }
      } else {
        Toast({ type: "error", message: "Something went wrong" });
      }
      setShowInputs(false);
    },
  });

  const [removeOwnerAndExecutiveFn, { loading: removingOwnerAndExecutive }] =
    useMutation(REMOVE_OWNER_AND_EXECUTIVE, {
      refetchQueries: [READ_OWNERS_AND_EXECUTIVES],
    });

  const { ref: homeAddressAutoCompleteRef } = usePlacesWidget({
    apiKey: NEXT_PUBLIC_GOOGLE_MAP_KEY,
    onPlaceSelected: (place) => {
      if (place.address_components) {
        let streetAddress = "";
        let zip = "";
        let state = "";
        let city = "";
        for (const component of place.address_components) {
          const componentType = component.types;
          if (componentType.includes("street_number")) {
            streetAddress = `${component.long_name} ${streetAddress}`;
          }
          if (componentType.includes("route")) {
            streetAddress += component.long_name;
          }
          if (componentType.includes("postal_code")) {
            zip = component.long_name;
          }
          if (componentType.includes("administrative_area_level_1")) {
            state = component.short_name;
          }
          if (
            componentType.includes("locality") ||
            componentType.includes("sublocality")
          ) {
            city = component.long_name;
          }
        }
        setFieldValue("homeAddress.zip", zip);
        setFieldValue("homeAddress.state", state);
        setFieldValue("homeAddress.city", city);
        setFieldValue("homeAddress.streetAddress", streetAddress);
      }
    },
    options: {
      componentRestrictions: { country: "us" },
      fields: ["address_components"],
      types: ["address"],
    },
  });

  const autoCompleteRef = useRef({
    homeAddressAutoCompleteRef,
  });

  const onEdit = (ownerAndExecutive: OwnerAndExecutiveType) => {
    if (ownerAndExecutive.representative) {
      onJumpToStep(4);
    } else {
      setValues({
        email: ownerAndExecutive.email,
        firstName: ownerAndExecutive.firstName,
        lastName: ownerAndExecutive.lastName,
        id: ownerAndExecutive.id,
        phoneNumber: phoneFormatter(ownerAndExecutive.phoneNumber || ""),
        ssn: ownerAndExecutive.ssn || "",
        dateOfBirth: ownerAndExecutive.dateOfBirth || "",
        executiveJobTitle:
          ownerAndExecutive.executiveJobTitle || ENUM_JOB_TITLE.OWNER,
        homeAddress: {
          id: ownerAndExecutive.address?.id || "",
          streetAddress: ownerAndExecutive.address?.streetAddress || "",
          streetDetails: ownerAndExecutive.address?.streetDetails || "",
          state: ownerAndExecutive.address?.state || "",
          city: ownerAndExecutive.address?.city || "",
          zip: ownerAndExecutive.address?.zip || "",
          type: ENUM_ADDRESS_TYPE.HOME,
        },
      });
      setShowInputs(true);
      // eslint-disable-next-line prettier/prettier, no-console
      console.log("here");
    }
  };

  const onAddNewOwner = () => {
    if (ownersAndExecutives.length === 4) {
      Toast({ type: "info", message: "Maximum of 4 owners allowed" });
      return;
    }
    resetForm();
    // setValues({
    //   id: "",
    //   firstName: "",
    //   lastName: "",
    //   phoneNumber: "",
    //   ssn: "",
    //   dateOfBirth: "",
    //   executiveJobTitle: "OWNER",
    //   email: "",
    //   homeAddress: {
    //     city: "",
    //     state: "",
    //     streetAddress: "",
    //     streetDetails: "",
    //     type: ENUM_ADDRESS_TYPE.HOME,
    //     zip: "",
    //     id: "",
    //   },
    // });
    setShowInputs(true);
    // eslint-disable-next-line prettier/prettier, no-console
    console.log("here");
  };

  const onRemoveOwner = (id: string) => {
    removeOwnerAndExecutiveFn({
      variables: {
        data: {
          id: id,
        },
      },
    });
  };

  return (
    <BeneficialOwnersUI
      {...{
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        handleBlur,
        errors,
        touched,
        setValues,
        resetForm,
        ...rest,
        isSubmittingOnboardStatus,
        ownersAndExecutives,
        onEdit,
        showInputs,
        onAddNewOwner,
        nextStep,
        onPreviousStep,
        setShowInputs,
        removingOwnerAndExecutive,
        onRemoveOwner,
      }}
      ref={autoCompleteRef}
    />
  );
};

function FetchInfo(props: {
  readCurrentUserOnboard: UserOnboardType;
  nextStep: (jumpStepBy?: number) => void;
  onJumpToStep: (step: number) => void;
  onPreviousStep: () => void;
  isSubmittingOnboardStatus: boolean;
}) {
  const { data, loading } = useReadOwnersAndExecutives();
  if (loading) {
    return <div className="my-6 text-center text-black">Loading...</div>;
  }

  const { ownersAndExecutives } = data as {
    ownersAndExecutives: OwnerAndExecutiveType[];
  };

  return (
    <BeneficialOwners
      {...{ ...props, ownersAndExecutives: ownersAndExecutives }}
    />
  );
}

export default FetchInfo;
