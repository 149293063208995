/** @format */
import { ENUM_PRODUCT_NAMES } from "@prisma/client";
import getConfig from "next/config";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { Button, ButtonLink, ButtonVariant } from "../../../buttons";
import { SignupModal } from "../../../modals/index-www";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL, NEXT_PUBLIC_WWW_URL } = publicRuntimeConfig;
/* eslint-disable-next-line */
export interface WwwFooterProps {}

export function WwwFooter(props: WwwFooterProps) {
  const router = useRouter();
  let fleetupDisclaimer = false;
  let nalpDisclaimer = false;
  let npmaDisclaimer = false;
  let phccDisclaimer = false;
  let geotabDisclaimer = false;
  if (router.pathname === "/partners/fleetup") {
    fleetupDisclaimer = true;
  }
  if (router.pathname === "/partners/nalp") {
    nalpDisclaimer = true;
  }
  if (router.pathname === "/partners/npma") {
    npmaDisclaimer = true;
  }
  if (router.pathname === "/partners/phcc") {
    phccDisclaimer = true;
  }
  if (router.pathname === "/partners/geotabconnect") {
    geotabDisclaimer = true;
  }

  const [showSignupModal, setshowSignupModal] = useState(false);

  return (
    <>
      <div
        className="min-h-[550px] h-full md:min-h-[650px] relative text-white"
        style={{
          background: "linear-gradient(rgb(87, 33, 19) 0%, rgb(0, 0, 0) 100%)",
        }}
      >
        <div className="container pt-6 sm:pt-16">
          <div className="flex flex-row flex-wrap w-full gap-5">
            <div className="relative w-48 max-w-[300px] h-12 max-h-[200px]">
              <Image
                className="p-0 m-0 hover:cursor-pointer"
                src={`${NEXT_PUBLIC_CDN_URL}/common/roadflex-logos/svg/roadflex-logo-white.svg`}
                alt="RoadFlex logo"
                layout="fill"
              />
            </div>
          </div>
        </div>
        <div className="relative" style={{ zIndex: 1 }}>
          <div className="container flex items-center py-6 text-xs md:text-base">
            <div className="flex flex-row flex-wrap w-full sm:mt-6 sm:mb-12 sm:gap-5 justify-left sm:justify-between">
              <div className="hidden sm:block">
                <div className="flex flex-col gap-y-2 md:gap-y-3">
                  <div className="font-semibold text-white md:block">
                    Solutions
                  </div>
                  <Link href="/fleetcard">
                    <a
                      href="/fleetcard"
                      className="flex items-center text-white cursor-pointer"
                    >
                      Fleet card
                    </a>
                  </Link>
                  <Link href="/prefunded">
                    <a
                      href="/prefunded"
                      className="flex items-center text-white cursor-pointer"
                    >
                      Prefunded Account
                    </a>
                  </Link>
                </div>
              </div>
              <div className="hidden sm:block">
                <div className="flex flex-col gap-y-2 md:gap-y-3">
                  <div className="font-semibold text-white md:block">
                    Benefits
                  </div>
                  <Link href="/control-and-reporting">
                    <a
                      href="/control-and-reporting"
                      className="flex items-center text-white cursor-pointer"
                    >
                      Controls & Reporting
                    </a>
                  </Link>
                  <Link href="/security-features">
                    <a
                      href="/security-features"
                      className="flex items-center text-white cursor-pointer"
                    >
                      Security Features
                    </a>
                  </Link>
                  <Link href="/integrations">
                    <a
                      href="/integrations"
                      className="flex items-center text-white cursor-pointer"
                    >
                      Integrations
                    </a>
                  </Link>

                  <Link href="/competition">
                    <a
                      href="/competition"
                      className="flex items-center text-white cursor-pointer"
                    >
                      Vs. the Competition
                    </a>
                  </Link>
                </div>
              </div>
              <div className="hidden sm:block">
                <div className="flex flex-col gap-y-2 md:gap-y-3">
                  <div className="items-center font-semibold text-white md:block">
                    Company
                  </div>
                  <Link href="/blog">
                    <a
                      href="/blog"
                      className="flex items-center text-white cursor-pointer"
                    >
                      Blog
                    </a>
                  </Link>
                  <Link href="/partnership">
                    <a
                      href="/affiliate-program"
                      className="flex items-center text-white cursor-pointer"
                    >
                      Become a partner
                    </a>
                  </Link>
                  <Link href="/ecosystem">
                    <a
                      href="/ecosystem"
                      className="flex items-center text-white cursor-pointer"
                    >
                      Ecosystem
                    </a>
                  </Link>
                  {/* <Link href="/broker">
                    <a
                      href="/broker"
                      className="flex items-center text-white cursor-pointer"
                    >
                      Brokers
                    </a>
                  </Link> */}
                  <Link href="/veteran">
                    <a
                      href="/veteran"
                      className="flex items-center text-white cursor-pointer"
                    >
                      RoadFlex for Veterans
                    </a>
                  </Link>
                </div>
              </div>
              <div className="sm:border-l-2 sm:border-gray-300 "></div>

              <div className="flex flex-col gap-y-2 md:gap-y-3">
                <div className="flex flex-row">
                  <span>
                    <Link href="https://www.facebook.com/RoadFlexHQ">
                      <a
                        href="https://www.facebook.com/RoadFlexHQ"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <div className="w-[20px] h-[20px] relative">
                          <Image
                            className="h-12 p-0 m-0 hover:cursor-pointer"
                            src={`${NEXT_PUBLIC_CDN_URL}/common/partner-logos/logo-facebook.svg`}
                            alt="RoadFlex Facebook"
                            layout="fill"
                            objectFit="contain"
                          />
                        </div>
                      </a>
                    </Link>
                  </span>
                  <span>
                    <Link href="https://www.linkedin.com/company/roadflex/">
                      <a
                        href="https://www.linkedin.com/company/roadflex/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="w-[20px] ml-4 h-[20px] relative">
                          <Image
                            className="h-12 p-0 m-0 hover:cursor-pointer"
                            src={`${NEXT_PUBLIC_CDN_URL}/common/partner-logos/logo-linkedin.svg`}
                            alt="RoadFlex LinkedIn"
                            layout="fill"
                            objectFit="contain"
                          />
                        </div>
                      </a>
                    </Link>
                  </span>
                </div>
                <div className="items-center my-2 text-lg font-semibold text-white">
                  Connect with Us
                </div>
                <div className="flex flex-col sm:flex-row">
                  <div className="">
                    <div className="font-medium sm:mb-2">Customer Support</div>
                    <div>
                      <Link href="tel:8778707440">
                        <div className="cursor-pointer">(877) 870-7440</div>
                      </Link>
                    </div>
                    <div>
                      <a
                        href="mailto:support@roadflex.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@roadflex.com
                      </a>
                    </div>
                  </div>
                  <div className="sm:border-l-2 sm:border-gray-300 sm:mx-8"></div>

                  <div className="">
                    <div className="mt-4 sm:mb-2 sm:mt-0 font-meedium">
                      Speak to Sales
                    </div>
                    <div>
                      <Link href="tel:2133205650">
                        <div className="cursor-pointer">(213) 320-5650</div>
                      </Link>
                    </div>
                    <div>
                      <a
                        href={`${NEXT_PUBLIC_WWW_URL}/contact-us`}
                        className="cursor-pointer"
                      >
                        {" "}
                        Contact Form
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border border-b-0 border-l-0 border-r-0 border-[#D17139] ">
            <div className="container flex flex-col pb-24 mt-5 md:pb-8 md:mt-20">
              <div className="">
                <Link href="/terms-of-service">
                  <a
                    href="/terms-of-service"
                    className="text-xs font-semibold text-white sm:text-base"
                  >
                    Terms of Service
                  </a>
                </Link>
                <Link href="/privacy-policy">
                  <a
                    href="/privacy-policy"
                    className="ml-2 text-xs font-semibold text-white md:ml-8 sm:text-base"
                  >
                    Privacy Policy
                  </a>
                </Link>
                <Link href="/subscription-agreement">
                  <a
                    href="/subscription-agreement"
                    className="ml-2 text-xs font-semibold text-white md:ml-8 sm:text-base"
                  >
                    Subscription Agreement
                  </a>
                </Link>
              </div>
              <div className="py-8 mb-5 space-y-2 text-[10px] text-white md:mb-20 md:space-y-4">
                <div className="">
                  The wordmark RoadFlex, the RoadFlex logo, and other RoadFlex
                  trademarks are registered and/or common law trademarks of
                  Openlane Technology Corporation, d/b/a RoadFlex. All other
                  trademarks displayed are the property of their respective
                  owners and are used by RoadFlex for informational purposes
                  only. Third party trademarks may be used on this website under
                  license. Unless otherwise noted, RoadFlex is not affiliated
                  with or endorsed by the owner of any third-party trademarks
                  displayed.{" "}
                </div>
                <div className="">
                  Visa is a trademark owned by Visa International Service
                  Association and is used under license.
                </div>
                <div>
                  The RoadFlex Fleet card is issued by Celtic Bank pursuant to a
                  license by Visa. The specific issuing bank for your card will
                  be identified on the back of your card and in your account
                  agreement. The RoadFlex Fleet Card is subject to approval.
                </div>
                <div className="">
                  Please visit the RoadFlex Privacy Policy and RoadFlex Terms of
                  Use for important legal disclosures.
                </div>
                <div className="">
                  Images are representational, do not represent all current
                  product capabilities and may represent future features.
                </div>
                <div className="">
                  The per-gallon discount on fuel purchases is not applicable to
                  international purchases and purchases at fuel merchants that
                  do not provide line-item transaction data on fuel purchases.
                </div>
                <div className="">
                  <div>
                    Copyright &copy; 2025 Openlane Technology Corporation, d/b/a
                    RoadFlex. Terms and conditions apply and are subject to
                    change. All rights reserved.
                  </div>
                </div>
                {fleetupDisclaimer && (
                  <div>
                    <div className="">
                      *FleetUp Promotional Offer Terms
                      <div>
                        To qualify for your statement credit, you must be an
                        active FleetUp user. The sign-up bonus will be credited
                        to your statement once your account has been open for
                        two months and you have spent the required amount on
                        fuel purchases within the first 60 days of opening your
                        account.
                      </div>
                      <div className="mt-2">
                        To receive the sign-on bonus, your RoadFlex Account must
                        be current and active at the time the bonus is awarded
                        (not past due or canceled). Any user on a single
                        RoadFlex account can make eligible purchases. Only
                        purchases count toward the spending threshold; fees or
                        other non-purchase charges do not contribute to your
                        spending amount.
                      </div>
                      <div className="mt-2">
                        Eligible purchases can be made by any cardholder on a
                        single RoadFlex account. In some cases, purchases may
                        fall outside the 60-day period due to delays in
                        merchants submitting transactions or differences between
                        the purchase date and transaction date.{" "}
                      </div>
                      <div className="mt-2">
                        Your timeframe to meet the minimum spending requirement
                        may be less than 60 days if there is a delay in
                        receiving your physical RoadFlex cards.{" "}
                      </div>
                      <div className="mt-2">
                        We reserve the right to update or replace these offer
                        terms at any time by posting a revised version here. We
                        may change eligibility criteria, restrictions, and
                        requirements at our discretion. Additionally, we may
                        terminate promotional offers at any time.{" "}
                      </div>
                      <div className="mt-2">
                        This offer cannot be combined with another RoadFlex
                        sign-up offer.{" "}
                      </div>
                    </div>
                  </div>
                )}
                {npmaDisclaimer && (
                  <div>
                    <div className="">
                      *NPMA Promotional Offer Terms
                      <div>
                        To qualify for your statement credit, you must be an
                        active NPMA members. The sign-up bonus will be credited
                        to your statement once your account has been open for
                        two months and you have spent the required amount on
                        fuel purchases within the first 60 days of opening your
                        account.
                      </div>
                      <div className="mt-2">
                        o receive the sign-on bonus, your RoadFlex Account must
                        be current and active at the time the bonus is awarded
                        (not past due or canceled). Any user on a single
                        RoadFlex account can make eligible purchases. Only
                        purchases count toward the spending threshold; fees or
                        other non-purchase charges do not contribute to your
                        spending amount.
                      </div>
                      <div className="mt-2">
                        Eligible purchases can be made by any cardholder on a
                        single RoadFlex account. In some cases, purchases may
                        fall outside the 60-day period due to delays in
                        merchants submitting transactions or differences between
                        the purchase date and transaction date.
                      </div>
                      <div className="mt-2">
                        Your timeframe to meet the minimum spending requirement
                        may be less than 60 days if there is a delay in
                        receiving your physical RoadFlex cards.
                      </div>
                      <div className="mt-2">
                        We reserve the right to update or replace these offer
                        terms at any time by posting a revised version here. We
                        may change eligibility criteria, restrictions, and
                        requirements at our discretion. Additionally, we may
                        terminate promotional offers at any time.
                      </div>
                      <div className="mt-2">
                        This offer cannot be combined with another RoadFlex
                        sign-up offer.
                      </div>
                    </div>
                  </div>
                )}
                {nalpDisclaimer && (
                  <div>
                    <div className="">
                      *NALP Promotional Offer Terms
                      <div>
                        To qualify for your statement credit, you must be an
                        active NALP members. The sign-up bonus will be credited
                        to your statement once your account has been open for
                        two months and you have spent the required amount on
                        fuel purchases within the first 60 days of opening your
                        account.
                      </div>
                      <div className="mt-2">
                        To receive the sign-on bonus, your RoadFlex Account must
                        be current and active at the time the bonus is awarded
                        (not past due or canceled). Any user on a single
                        RoadFlex account can make eligible purchases. Only
                        purchases count toward the spending threshold; fees or
                        other non-purchase charges do not contribute to your
                        spending amount.
                      </div>
                      <div className="mt-2">
                        Eligible purchases can be made by any cardholder on a
                        single RoadFlex account. In some cases, purchases may
                        fall outside the 60-day period due to delays in
                        merchants submitting transactions or differences between
                        the purchase date and transaction date.
                      </div>
                      <div className="mt-2">
                        Your timeframe to meet the minimum spending requirement
                        may be less than 60 days if there is a delay in
                        receiving your physical RoadFlex cards.
                      </div>
                      <div className="mt-2">
                        We reserve the right to update or replace these offer
                        terms at any time by posting a revised version here. We
                        may change eligibility criteria, restrictions, and
                        requirements at our discretion. Additionally, we may
                        terminate promotional offers at any time.
                      </div>
                      <div className="mt-2">
                        This offer cannot be combined with another RoadFlex
                        sign-up offer.{" "}
                      </div>
                    </div>
                  </div>
                )}
                {phccDisclaimer && (
                  <div>
                    <div className="">
                      *PHCC Promotional Offer Terms
                      <div>
                        To qualify for your statement credit, you must be an
                        active PHCC members. The sign-up bonus will be credited
                        to your statement once your account has been open for
                        two months and you have spent the required amount on
                        fuel purchases within the first 60 days of opening your
                        account.
                      </div>
                      <div className="mt-2">
                        To receive the sign-on bonus, your RoadFlex Account must
                        be current and active at the time the bonus is awarded
                        (not past due or canceled). Any user on a single
                        RoadFlex account can make eligible purchases. Only
                        purchases count toward the spending threshold; fees or
                        other non-purchase charges do not contribute to your
                        spending amount.
                      </div>
                      <div className="mt-2">
                        Eligible purchases can be made by any cardholder on a
                        single RoadFlex account. In some cases, purchases may
                        fall outside the 60-day period due to delays in
                        merchants submitting transactions or differences between
                        the purchase date and transaction date.
                      </div>
                      <div className="mt-2">
                        Your time frame to meet the minimum spending requirement
                        may be less than 60 days if there is a delay in
                        receiving your physical RoadFlex cards.
                      </div>
                      <div className="mt-2">
                        We reserve the right to update or replace these offer
                        terms at any time by posting a revised version here. We
                        may change eligibility criteria, restrictions, and
                        requirements at our discretion. Additionally, we may
                        terminate promotional offers at any time.
                      </div>
                      <div className="mt-2">
                        This offer cannot be combined with another RoadFlex
                        sign-up offer.{" "}
                      </div>
                    </div>
                  </div>
                )}
                {geotabDisclaimer && (
                  <div>
                    <div className="">
                      <strong>*Geotab Customer Promotional Offer Terms</strong>
                      <div>
                        To qualify for a complementary vacation, you must be an
                        active Geotab customer. The complementary trip will be
                        redeemable to you once your new RoadFlex account has
                        been open for two months and all your RoadFlex cards are
                        actively used by their assigned users for at least 30
                        days, with a minimum spend of $500 per user per month.
                      </div>
                      <div className="mt-2">
                        To receive the complementary trip, your RoadFlex Account
                        must be current and active at the time the trip is
                        awarded (not past due or canceled). Any user on a single
                        RoadFlex account can make eligible purchases.
                      </div>
                      <div className="mt-2">
                        Your choice of vacation destination for your
                        complementary trip will depend on your fleet size and
                        spending needs. The bigger your fleet, the more vacation
                        destination choices you’ll have. Complementary trips
                        come with flights and hotel expenses included for 3
                        days, for 2 people. Sample destinations include Las
                        Vegas, New York City, New Orleans, San Diego and more.
                        Please ask our Account Executives for more information.
                      </div>
                      <div className="mt-2">
                        We reserve the right to update or replace these offer
                        terms at any time by posting a revised version here. We
                        may change eligibility criteria, restrictions, and
                        requirements at our discretion. Additionally, we may
                        terminate promotional offers at any time.
                      </div>
                      <div className="mt-2">
                        This offer cannot be combined with another RoadFlex
                        offer.
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-[64px] flex flex-row w-full z-10 bg-dullwhite fixed bottom-0 md:hidden">
        <div className="container flex flex-row items-center justify-end">
          <ButtonLink
            href="tel:8778707440"
            variant={ButtonVariant.OrangeLightOutline}
            className="w-full mr-3 text-base font-bold text-center text-orange-500 rounded-lg"
          >
            Call Now
          </ButtonLink>
          <Button
            onClick={() => {
              setshowSignupModal(true);
            }}
            variant={ButtonVariant.Orange}
            className="w-full text-base font-bold rounded-lg"
          >
            Start Application
          </Button>
        </div>
      </div>
      {showSignupModal && (
        <SignupModal
          productName={
            router.pathname === "/"
              ? ENUM_PRODUCT_NAMES.FUELCARD
              : ENUM_PRODUCT_NAMES.FACTOR
          }
          showSignupModal={showSignupModal}
          setshowSignupModal={setshowSignupModal}
        ></SignupModal>
      )}
    </>
  );
}

export default WwwFooter;
