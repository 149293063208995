import { ENUM_CUSTOMER_TIER } from "@prisma/client";
import { AdminType } from "@roadflex/types";
import { Dialog } from "primereact/dialog";
import { DropdownChangeParams } from "primereact/dropdown";
import { FocusEvent, useRef } from "react";
import CurrencyInput from "react-currency-input-field";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import { CustomMultiSelect, Select } from "../dropdowns";
import AdminModalLoader from "../loader/admin-modal-loader";
import { Toast } from "../toast-message/toast";

type GenericAdminActionModalProps = {
  title: string;
  variableName: string;
  show: boolean;
  setShow: (value: boolean) => void;
  handleSubmit: () => void;
  submitting: boolean;
  multiSelectOptions?: { value: string; label: string }[];
  dropDownOptions?:
    | AdminType[]
    | { id: string; firstName: string; lastName: string }[];
  handleChange: (e: DropdownChangeParams) => void;
  values:
    | {
        userEmail: string;
        customerTier: ENUM_CUSTOMER_TIER;
        customerSupportAgent: string;
        salesAgent: string;
      }
    | {
        email: string;
        additionalCategory: string;
      }
    | {
        billingCycleId: string;
        waiveLateFees: string;
      };
  handleBlur: (e: FocusEvent<HTMLInputElement, Element>) => void;
  setFieldValue: (field: string, value: string) => void;
};

export function GenericAdminActionModal({
  title,
  variableName,
  handleSubmit,
  submitting,
  show,
  setShow,
  dropDownOptions,
  multiSelectOptions,
  handleChange,
  values,
  handleBlur,
  setFieldValue,
}: GenericAdminActionModalProps) {
  const cancelButtonRef = useRef(null);
  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-end mt-4">
        <Button
          variant={ButtonVariant.Primary}
          size={ButtonSize.AppSize}
          disabled={submitting}
          loading={submitting}
          className=""
          onClick={() => handleSubmit()}
        >
          Confirm
        </Button>
        <Button
          type={ButtonType.Button}
          variant={ButtonVariant.SecondaryOutline}
          size={ButtonSize.AppSize}
          disabled={submitting}
          className="ml-3"
          onClick={() => setShow(false)}
          ref={cancelButtonRef}
        >
          Close
        </Button>
      </div>
    );
  };

  const validateValue = (value: string | undefined): void => {
    const rawValue = value === undefined ? "undefined" : value;
    setFieldValue("waiveLateFees", rawValue.toString() || "0");
    if (Number.isNaN(Number(value)) && value !== undefined) {
      Toast({
        type: "error",
        message: "Enter a valid number",
      });
    }
  };

  return (
    <Dialog
      header={<div className="text-base">{title}</div>}
      style={{ minWidth: "50vw", maxWidth: "80vw", fontFamily: "Inter" }}
      visible={show}
      onHide={() => setShow(false)}
      closeOnEscape={true}
      closable={false}
      footer={renderFooter}
    >
      <AdminModalLoader showLoading={submitting} />
      {dropDownOptions && (
        <Select
          onChange={handleChange}
          onBlur={handleBlur}
          name={variableName}
          value={values[`${variableName}` as keyof typeof values]}
          options={[
            {
              label: "-- Select an option --",
              value: "",
              disabled: true,
            },
          ].concat(
            dropDownOptions.map(
              (
                option:
                  | AdminType
                  | { id: string; firstName: string; lastName: string },
              ) => {
                return {
                  label: `${option.firstName ? option.firstName : ""} ${
                    option.lastName ? option.lastName : ""
                  }`,
                  value: option.id || "",
                  key: option.id || "",
                  disabled: false,
                };
              },
            ),
          )}
        ></Select>
      )}
      {multiSelectOptions && (
        <CustomMultiSelect
          value={(
            (
              values as {
                email: string;
                additionalCategory: string;
              }
            ).additionalCategory || ""
          ).split(",")}
          options={multiSelectOptions}
          placeholder=""
          maxSelectedLabels={2}
          className="w-full !text-sm h-10 flex items-center"
          onChange={(e) => {
            const newString = e.value.filter((x: string) => x !== "").join(",");
            setFieldValue(variableName, newString);
          }}
        />
      )}
      {variableName === "waiveLateFees" && (
        <div className="w-full">
          <div className="mb-1 whitespace-nowrap">
            Reduce late Fees amount by
          </div>
          <div className="relative w-full">
            <CurrencyInput
              placeholder="$"
              name="waiveLateFees"
              onBlur={handleBlur}
              className="block w-full py-2 pl-3 pr-12 border rounded-md sm:text-sm focus:outline-none focus:ring-0 focus:border-brown-800"
              allowDecimals={true}
              onValueChange={validateValue}
              prefix={"$"}
              step={10}
            />
          </div>
        </div>
      )}
    </Dialog>
  );
}

export default GenericAdminActionModal;
