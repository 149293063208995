/** @format */
/** @format */
import { useMutation } from "@apollo/client";
import { READ_BILLING_SUMMARY } from "@roadflex/graphql";
import { BillingSummaryType } from "@roadflex/types";

export const useReadBillingSummary = () => {
  const [makeBillingSummary, { loading: makeBillingSummaryLoading }] =
    useMutation<{
      readBillingSummary: BillingSummaryType[];
    }>(READ_BILLING_SUMMARY, {
      fetchPolicy: "no-cache",
    });
  return { makeBillingSummary, makeBillingSummaryLoading };
};
