/** @format */

import { GoogleMap, Marker } from "@react-google-maps/api";
import { dollarFormatter } from "@roadflex/constants";
import { FuelTypeStat, GoogleMapsMarkersType, Station } from "@roadflex/types";
import classNames from "classnames";
import getConfig from "next/config";
import { useEffect, useState } from "react";
import SwiperCore, { EffectFlip, Navigation, Pagination } from "swiper";
// import "swiper/css";
// import "swiper/css/autoplay";
// import "swiper/css/pagination";
import { usePlatformDetect } from "@roadflex/react-hooks";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Pagination, EffectFlip]);
const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

type FuelStationFinderMapUIProps = {
  mapOptions: google.maps.MapOptions;
  markers: GoogleMapsMarkersType[];
  stats: FuelTypeStat[];
  closestMarker: {
    station: Station;
    distanceMiles: number;
  } | null;
  handleMouseOver: (info: number) => void;
  handleMouseExit: () => void;
  showInfo: number | null;
  parameters: {
    latitude: number;
    longitude: number;
    address: string;
  };
};

const FuelStationFinderMapUI = ({
  mapOptions,
  markers,
  stats,
  closestMarker,
  handleMouseOver,
  handleMouseExit,
  showInfo,
  parameters,
}: FuelStationFinderMapUIProps) => {
  const [swiperInstance, setSwiperInstance] = useState<SwiperCore | null>(null);
  const { isMobile } = usePlatformDetect();
  const slideTo = (index: number) => {
    if (swiperInstance) swiperInstance.slideTo(index);
  };

  useEffect(() => {
    if (showInfo) {
      slideTo(showInfo <= markers.length - 2 ? showInfo : markers.length - 2);
    }
  }, [showInfo]);
  const mapCenter = {
    lat: parameters?.latitude,
    lng: parameters?.longitude,
  };

  return (
    <>
      <div
        className={`${isMobile() ? "h-[calc(35vh)]" : "h-[calc(60vh)]"} mb-8`}
      >
        <GoogleMap
          options={mapOptions}
          zoom={15}
          center={mapCenter}
          mapTypeId={"roadmap"}
          mapContainerStyle={{
            width: "100%",
            height: "100%",
            borderRadius: "15px 15px 15px 15px",
          }}
        >
          <Marker position={mapCenter} />
          {markers?.map((mark, index) => (
            <Marker
              key={index}
              title={mark?.title}
              position={{ lat: mark?.latitude, lng: mark?.longitude }}
              onMouseOver={(e) => handleMouseOver(index)}
              onMouseOut={handleMouseExit}
              icon={`${NEXT_PUBLIC_CDN_URL}/common/images/fuel-station-used-in-app-station-finder.png`}
            ></Marker>
          ))}
        </GoogleMap>
      </div>
      <div className="space-y-6">
        {stats.length > 0 && (
          <div className="p-4 bg-white rounded-md shadow">
            <div className="mb-3 font-semibold text-brown-500 md:text-lg">
              Fuel Pricing Statistics
            </div>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4">
              {stats?.map((stat, index) => (
                <div
                  key={index}
                  className="flex flex-col gap-2 p-3 border border-gray-200 rounded-md"
                >
                  <div className="flex items-center justify-between">
                    <span className="font-medium text-gray-700">
                      {stat.fuelType}
                    </span>
                  </div>
                  <div className="text-sm text-gray-600">
                    <div>
                      <strong>Lowest:</strong>{" "}
                      {dollarFormatter(stat.lowest.price)} at{" "}
                      <span
                        // Conditionally underline the station title if hovered
                        className={classNames(
                          "italic hover:underline cursor-pointer",
                        )}
                        onMouseOver={() =>
                          handleMouseOver(
                            markers.findIndex(
                              (m) =>
                                m.latitude === stat.lowest.station.latitude,
                            ),
                          )
                        }
                        onMouseOut={handleMouseExit}
                      >
                        {stat.lowest.station.title}
                      </span>
                    </div>
                    <div>
                      <strong>Highest:</strong>{" "}
                      {dollarFormatter(stat.highest.price)} at{" "}
                      <span
                        className={classNames(
                          "italic hover:underline cursor-pointer",
                        )}
                        onMouseOver={() =>
                          handleMouseOver(
                            markers.findIndex(
                              (m) =>
                                m.latitude === stat.highest.station.latitude,
                            ),
                          )
                        }
                        onMouseOut={handleMouseExit}
                      >
                        {stat.highest.station.title}
                      </span>
                    </div>
                    <div>
                      <strong>Avg:</strong> {dollarFormatter(stat.average)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {closestMarker && (
          <div className="flex flex-col p-4 bg-white rounded-md shadow">
            <div className="mb-3 font-semibold text-brown-500 md:text-lg">
              Closest Fuel Station
            </div>
            <div className="text-gray-700">
              <strong>Station:</strong>{" "}
              <span
                className={classNames("hover:underline cursor-pointer")}
                onMouseOver={() =>
                  handleMouseOver(
                    markers.findIndex(
                      (m) => m.latitude === closestMarker.station.latitude,
                    ),
                  )
                }
                onMouseOut={handleMouseExit}
              >
                {" "}
                {closestMarker.station.title}
              </span>
            </div>
            <div className="text-gray-700">
              <strong>Distance:</strong>{" "}
              {closestMarker.distanceMiles.toFixed(2)} miles
            </div>
          </div>
        )}

        {markers.length > 0 && (
          <div className="p-4 bg-white rounded-md shadow">
            <div className="mb-3 font-semibold text-brown-500 md:text-lg">
              Fuel Stations
            </div>
            <Swiper
              onSwiper={(swiper) => setSwiperInstance(swiper)}
              slidesPerView={3}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                "@0.00": {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                "@0.75": {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                "@1.00": {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
                "@1.50": {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
              lazyPreloadPrevNext={3}
              className="child:!ease-linear"
            >
              {markers?.length > 0 &&
                markers.map((marker: GoogleMapsMarkersType, idx: number) => (
                  <SwiperSlide key={idx}>
                    <div
                      className={classNames(
                        "p-2 rounded-md",
                        idx === showInfo ? "bg-orange-200" : "bg-lightyellow",
                      )}
                      // onMouseEnter={() => handleMouseOver(idx)}
                      // onMouseLeave={() => handleMouseExit()}
                    >
                      {/* Station Image */}
                      {marker.image && (
                        <img
                          src={marker.image}
                          alt={marker.title}
                          className="object-cover w-full h-32 mb-2 rounded-md"
                        />
                      )}

                      <div className="font-medium">{marker.title}</div>
                      <div className="text-sm text-gray-700">
                        <p>
                          Rating: {marker.rating ?? "-"} (# reviews:{" "}
                          {marker.ratingNumber ?? "-"})
                        </p>
                        <p>Address: {marker.vicinity}</p>
                      </div>

                      {/* Fuel Prices */}
                      {marker.fuelPrices?.length ? (
                        <div className="mt-2 space-y-1 text-sm text-gray-600">
                          {marker.fuelPrices.map((fuel, i) => (
                            <div key={i}>
                              <strong>{fuel.type}:</strong>{" "}
                              {dollarFormatter(
                                Number(fuel.units) +
                                  Number(fuel.nanos || 0) * 10 ** -9,
                              )}{" "}
                              <span className="text-xs text-gray-400">
                                (updated:{" "}
                                {fuel.updateTime.toString().split("T")[0]})
                              </span>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="mt-2 text-sm italic text-gray-500">
                          No price data available
                        </div>
                      )}
                    </div>
                  </SwiperSlide>
                ))}

              {/* Extra space */}
              <div className="h-12" />
            </Swiper>
          </div>
        )}
      </div>
    </>
  );
};

export default FuelStationFinderMapUI;
