/** @format */

import {
  Button,
  ButtonType,
  ButtonVariant,
  TextInput,
} from "@roadflex/react-components";
import { AdminSignInValues } from "@roadflex/types";
import { FormikProps } from "formik";

import OtpInput from "react-otp-input";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SignInUIProps extends FormikProps<AdminSignInValues> {}

export default function SignInUI({
  values,
  handleChange,
  handleSubmit,
  handleBlur,
  touched,
  errors,
  setFieldValue,
  ...rest
}: SignInUIProps) {
  return (
    <div className="flex items-center justify-center w-full min-h-full py-12 mt-10 md:px-4 sm:px-6 lg:px-8">
      <div className="container flex flex-col items-center justify-center h-full my-8">
        <div>
          <div
            className="px-12 sm:px-20 py-12 sm:py-16 rounded-xl min-h-[300px] md:min-h-[500px] w-[86vw] md:w-[1050px]"
            style={{
              background: "linear-gradient(130deg,#29120A 0, #823920 100%)",
            }}
          >
            <div className="w-full">
              <div className="mb-6 text-xl font-semibold text-center text-white">
                RoadFlex Log in
              </div>
              <form className="w-full" onSubmit={handleSubmit}>
                <div className="relative w-full">
                  <TextInput
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    errorMessage={errors.email}
                    touched={touched.email}
                    variant="responsive"
                    color="text-white"
                    required
                  ></TextInput>
                </div>
                <div className="relative w-full mt-4">
                  <TextInput
                    label="Password"
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password && Boolean(errors.password)}
                    errorMessage={errors.password}
                    touched={touched.password}
                    variant="responsive"
                    color="text-white"
                    required
                  ></TextInput>
                </div>
                {values.isOtpVerify ? (
                  <div className="relative w-full mt-6">
                    <div className="mb-6 text-sm font-extrabold text-center text-white md:text-base">
                      Please enter the 6-digit verification code we sent via
                      email:
                    </div>
                    <div className="w-full">
                      <div className="relative flex justify-center w-full">
                        <OtpInput
                          value={values.otp}
                          onChange={(otp: string) => {
                            setFieldValue("otp", otp);
                          }}
                          inputStyle={{
                            width: "1.5em",
                            height: "1.5em",
                            fontSize: "2em",
                            fontFamily: "Montserrat, sans-serif",
                            borderRadius: "0.25em",
                            border: "1px solid #823920",
                            boxShadow: "0 0 5px #ccc inset",
                          }}
                          inputType="tel"
                          renderSeparator={<span>&nbsp;</span>}
                          numInputs={6}
                          renderInput={(props) => <input {...props} />}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="mt-8">
                  <Button
                    type={ButtonType.Submit}
                    variant={ButtonVariant.Orange}
                    className="w-full"
                  >
                    Sign In
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
