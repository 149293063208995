/** @format */
import {
  DocumentMagnifyingGlassIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import { usePlatformDetect } from "@roadflex/react-hooks-www";
import { BankVerificationValues, PlaidType } from "@roadflex/types";
import classNames from "classnames";
import { FormikProps } from "formik";
import getConfig from "next/config";
import { MutableRefObject, useState } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import ErrorMessage from "../error-message/errorMessage";
import PlaidLink from "../fuelcard-components/plaidAlt";
import { OnboardBannerComponent } from "./common-banner-component";
const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_API_URL } = publicRuntimeConfig;

interface BankVerificationUIProps extends FormikProps<BankVerificationValues> {
  handleFiles: (files: FileList) => void;
  handleRemoveFile: (file: File) => void;
  isSubmittingOnboardStatus: boolean;
  linkToken: string;
  institution: MutableRefObject<string>;
  publicToken: MutableRefObject<string>;
  plaidData: PlaidType[];
  uploadedStatements: string[];
  customerType: string;
  onPreviousStep: () => void;
}
const BankVerificationUI = ({
  values,
  handleFiles,
  handleRemoveFile,
  handleSubmit,
  isSubmitting,
  isSubmittingOnboardStatus,
  setFieldValue,
  handleBlur,
  errors,
  linkToken,
  institution,
  publicToken,
  status,
  setStatus,
  plaidData,
  customerType,
  uploadedStatements,
  onPreviousStep,
}: BankVerificationUIProps) => {
  const [showStatementUI, setShowStatementUI] = useState(false);
  const { isMobile } = usePlatformDetect();
  return (
    <div
      className={classNames(
        isMobile() ? "flex-col" : "flex-row",
        "flex w-full h-full min-h-screen",
      )}
    >
      <OnboardBannerComponent
        header={
          customerType.includes("PREPAID")
            ? "You are applying for the prepaid fuel card"
            : showStatementUI
            ? `Your Finances: Upload Business Bank Statements`
            : `Your Finances: Connect Business Bank Account`
        }
        subText={
          customerType.includes("PREPAID")
            ? "Continue to review your application."
            : ""
        }
      />
      <div className="flex flex-col w-full px-4 py-8 md:p-16">
        <form onSubmit={handleSubmit} className="flex flex-col w-full">
          {customerType.includes("CREDIT") && (
            <div>
              <div className="mb-4 text-xs text-center md:mb-8 md:text-sm">
                {showStatementUI ? (
                  <span>
                    Upload bank statements for your{" "}
                    <span className="font-bold">
                      primary business bank account
                    </span>{" "}
                    with a good balance, showing business revenue from the most
                    recent three months
                  </span>
                ) : (
                  `We assess your revenue history to match you with the best fuel card option for your business.`
                )}
              </div>
              <div className="space-y-4 text-xs md:text-sm">
                <div className="">
                  For credit users, we need your proof of revenue to approve
                  your application and assign you a credit limit.
                </div>
                <div className="">
                  Get the best credit limit by lining a business bank account
                  with strongest revenue deposits. You can use another account
                  to pay later.
                </div>
              </div>
              <div className="my-4 h-[1px] w-full bg-orange-500 opacity-50"></div>
              <div
                className={classNames(!showStatementUI ? "block" : "hidden ")}
              >
                <div className="space-y-4">
                  <div className="font-semibold">
                    Option 1: Link Business Bank Account
                  </div>
                  <PlaidLink
                    linkToken={linkToken}
                    institution={institution}
                    plaidData={plaidData}
                    publicToken={publicToken}
                    setStatus={setStatus}
                    setFieldValue={setFieldValue}
                  />
                  <div className="flex flex-row">
                    <div className="relative mr-5">
                      <div className="text-center">
                        <LockClosedIcon
                          width={25}
                          height={25}
                          className="pointer-events-none"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <div className="text-sm">
                        We partner with Plaid for safe and secure connection to
                        your bank.
                      </div>
                      <div className="text-sm">
                        Approval in 2 business days.
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-4 h-[1px] w-full bg-orange-500 opacity-50"></div>

                <div className="space-y-4">
                  <div className="font-semibold">
                    Option 2: Upload Bank Statements
                  </div>
                  <div className="text-sm">
                    <div className="font-semibold">
                      Can&apos;t find your bank?
                    </div>
                    <div
                      className="hover:underline hover:cursor-pointer"
                      onClick={() => {
                        setShowStatementUI(true);
                      }}
                    >
                      Upload 3 months of business bank statements
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  showStatementUI ? "block" : "hidden ",
                  "space-y-4",
                )}
              >
                <ul className="ml-4 text-sm list-disc">
                  <li>
                    Only PDF files are accepted{" "}
                    <span>(Maximum size: 3MB/ file, 3 files)</span>
                  </li>
                  <li>
                    Must be downloaded directly from your digital bank, or
                    emailed from your bank (no scanned documents)
                  </li>
                  <li>
                    Must include transactions (payments and expenses) within
                    each of the past 3 months
                  </li>
                </ul>
                {uploadedStatements?.length > 0 && !values.docsReupload ? (
                  <div className="w-full my-10 text-sm text-center text-white md:text-base">
                    <div className="flex flex-col sm:flex-row">
                      Uploaded Statements :{" "}
                      {uploadedStatements.map((statement, idx) => (
                        <a
                          key={idx}
                          href={`${NEXT_PUBLIC_API_URL}/api/files/${statement}`}
                          rel="noreferrer"
                          target="_blank"
                          className="ml-2 underline underline-offset-2"
                        >
                          {`Statement ${idx + 1}`}
                        </a>
                      ))}
                    </div>
                    <div className="flex items-center justify-center mt-10">
                      If you want to upload new files, click{" "}
                      <Button
                        type={ButtonType.Button}
                        variant={ButtonVariant.Transparent}
                        size={ButtonSize.Normal}
                        className="!p-0 ml-1  underline underline-offset-1"
                        onClick={() => {
                          setFieldValue("docsReupload", true);
                        }}
                      >
                        here
                      </Button>
                      .
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="text-sm">
                      Click the area below to upload files from your device
                    </div>
                    <div className="flex items-center justify-center w-full mx-auto text-gray-500 bg-white rounded-lg cursor-pointer">
                      <label
                        htmlFor="statements"
                        className="flex flex-col w-full h-32 cursor-pointer"
                      >
                        <div className="flex flex-col items-center justify-center pt-7">
                          <DocumentMagnifyingGlassIcon
                            className="w-10 h-10 text-gray-500"
                            aria-hidden="true"
                          />
                          Browse
                        </div>
                        <input
                          className="hidden"
                          type="file"
                          id="statements"
                          name="statements"
                          onChange={(event) => {
                            handleFiles(event?.target?.files as FileList);
                            setStatus({ error: null });
                          }}
                          onBlur={handleBlur}
                          multiple
                          accept=".pdf"
                        />
                      </label>
                    </div>
                  </>
                )}
                {values.statements.length > 0 && (
                  <div className="flex flex-col text-black">
                    {(values.statements as File[]).map(
                      (statement, index: number) => (
                        <div
                          key={statement.name + index}
                          className="flex flex-row items-center"
                        >
                          <div className="mr-3">
                            {statement.name && (
                              <span className="my-3">{statement.name}</span>
                            )}
                            {!statement.name && (
                              <span className="my-3">{`Statement ${
                                index + 1
                              }`}</span>
                            )}
                          </div>
                          <button
                            type="button"
                            onClick={() => handleRemoveFile(statement as File)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="w-5 h-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1"
                                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                              />
                            </svg>
                          </button>
                        </div>
                      ),
                    )}
                  </div>
                )}
                <div className="text-sm text-center">
                  Approval in 5 business days
                </div>
                <div className="text-sm">
                  <div
                    className="hover:underline hover:cursor-pointer"
                    onClick={() => {
                      setShowStatementUI(false);
                    }}
                  >
                    Connect bank account instead?
                  </div>
                </div>
              </div>
              <div className="mt-4">
                {errors.statements && (
                  <div className="text-xs text-red-500">
                    {String(errors.statements)}
                  </div>
                )}
              </div>

              <div className="mt-4 text-sm text-center">
                Bank Information is required to qualify for a credit line
              </div>
              <div className="text-sm text-center">
                Apply for a prepaid RoadFlex card instead if you don't need
                credit
              </div>
              <div className="transition-all duration-150 ease-linear">
                <div className="col-span-6 sm:col-span-6">
                  <div className="mb-2"></div>
                </div>
                {status?.error && <ErrorMessage error={status.error} />}
              </div>
            </div>
          )}
          <div className="mt-6 text-right">
            <Button
              type={ButtonType.Submit}
              variant={ButtonVariant.Orange}
              className="flex flex-row items-center justify-center w-full"
              disabled={isSubmitting || isSubmittingOnboardStatus}
              loading={isSubmitting || isSubmittingOnboardStatus}
            >
              {isSubmitting || isSubmittingOnboardStatus
                ? "Loading..."
                : "Review Application"}
            </Button>
          </div>
          <div>
            <div className="flex items-center justify-center mt-1 text-xs text-center md:text-sm">
              <ShieldCheckIcon className="w-4 h-4 mr-1"></ShieldCheckIcon>
              We securely store and encrypt your personal information
            </div>
            <Button
              type={ButtonType.Button}
              variant={ButtonVariant.Transparent}
              className="pl-0"
              size={ButtonSize.Small}
              onClick={onPreviousStep}
            >
              &#60;&#60; Go back
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BankVerificationUI;
