/** @format */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dialog, Transition } from "@headlessui/react";
import { ENUM_SENDGRID_TEMPLATE } from "@prisma/client";
import { adminNotificationsEmailTemplateSchema } from "@roadflex/constants";
import { NotificationTemplatePropsType } from "@roadflex/types";
import { useFormik } from "formik";
import { Fragment, useEffect, useRef, useState } from "react";
import { Select } from "../../dropdowns";
import { TextInput } from "../../inputs";

type TemplateModalProps = {
  sendGridTemplates: any;
  savingTemplate: boolean;
  onSaveTemplate: ({
    id,
    templateName,
    email,
    variables,
    sendgridTemplateType,
  }: NotificationTemplatePropsType) => void;
  templatePopup: boolean;
  setTemplatePopup: (templatePopup: boolean) => void;
  templateData: NotificationTemplatePropsType;
  addedEmailAccounts: any;
  onTestTemplate: (values: NotificationTemplatePropsType) => void;
};
export const TemplateModal = (props: TemplateModalProps) => {
  const {
    sendGridTemplates,
    savingTemplate,
    templatePopup,
    onSaveTemplate,
    setTemplatePopup,
    templateData,
    addedEmailAccounts,
    onTestTemplate,
  } = props;

  const initValues = {
    templateName: templateData.templateName,
    id: templateData.id,
    email: templateData.email,
    testEmail: "",
    sendgridTemplateType: templateData.sendgridTemplateType,
    variables: templateData.variables,
  };
  const cancelButtonRef = useRef(null);

  const [showEditButton, setShowEditButton] = useState(
    templateData.templateName.length > 0 || false,
  );

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    submitForm,
    setValues,
    values,
    touched,
    isSubmitting,
    errors,
    ...rest
  } = useFormik({
    initialValues: initValues,
    validationSchema: adminNotificationsEmailTemplateSchema,
    onSubmit: async (value) => {
      onSaveTemplate(value);
    },
  });

  const initializeVariables = () => {
    const matchingTemplate = sendGridTemplates.find(
      (sgTemplate: { sendgridTemplateType: string }) =>
        sgTemplate.sendgridTemplateType === values.sendgridTemplateType,
    );
    // Function to initialize variables based on the matching template
    if (matchingTemplate) {
      const initialVars: { [key: string]: string } = {};
      matchingTemplate.variableNames.forEach((variable: string) => {
        initialVars[variable] = values.variables[variable] || "";
      });

      setValues((prevValues) => ({
        ...prevValues,
        variables: initialVars,
      }));
    }
  };

  useEffect(() => {
    // Initialize variables on component mount
    initializeVariables();
  }, [values.sendgridTemplateType]);

  return (
    <Transition.Root show={templatePopup} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        initialFocus={cancelButtonRef}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onClose={() => {}}
      >
        <form onSubmit={handleSubmit} className="w-full">
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-md shadow-xl sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-center text-gray-900"
                    >
                      {templateData?.templateName ? "Edit" : "Add"} Template
                    </Dialog.Title>
                    <div className="relative w-full">
                      <div className="mb-1 text-sm whitespace-nowrap">
                        Template Name*
                      </div>
                      <TextInput
                        type="text"
                        name="templateName"
                        required
                        value={values.templateName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={
                          showEditButton || templateData.templateName.length > 0
                        }
                        touched={touched.templateName}
                        error={
                          touched.templateName && Boolean(errors.templateName)
                        }
                        errorMessage={errors.templateName}
                      />
                    </div>
                    <div className="relative w-full mt-4">
                      <div className="mb-1 text-sm whitespace-nowrap">
                        Send from email*
                      </div>
                      <Select
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={showEditButton}
                        name="email"
                        value={values.email}
                        touched={touched.email}
                        error={touched.email && Boolean(errors.email)}
                        errorMessage={errors.email}
                        options={[
                          {
                            label: "-- Select an option --",
                            value: "",
                            disabled: true,
                          },
                        ].concat(
                          addedEmailAccounts?.map(
                            (
                              { email: emailVal }: any,
                              idx: React.Key | null | undefined,
                            ) => {
                              return {
                                label: emailVal,
                                value: emailVal,
                                key: idx,
                                disabled: false,
                              };
                            },
                          ),
                        )}
                      ></Select>
                    </div>
                    <div className="relative w-full mt-4">
                      <div className="mb-1 text-sm whitespace-nowrap">
                        SendGrid Template*
                      </div>
                      <Select
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={showEditButton}
                        name="sendgridTemplateType"
                        value={values.sendgridTemplateType}
                        touched={touched.sendgridTemplateType}
                        error={
                          touched.sendgridTemplateType &&
                          Boolean(errors.sendgridTemplateType)
                        }
                        errorMessage={errors.sendgridTemplateType}
                        options={[
                          {
                            label: "-- Select an option --",
                            value: "",
                            disabled: true,
                          },
                        ].concat(
                          Object.keys(ENUM_SENDGRID_TEMPLATE).map((key) => {
                            return {
                              label: key,
                              value: key,
                              key: key,
                              disabled: false,
                            };
                          }),
                        )}
                      ></Select>
                    </div>

                    <div className="relative w-full mt-4 mb-2">
                      <div className="text-base font-bold whitespace-nowrap">
                        Email Variables
                      </div>
                    </div>
                    {Object.keys(values.variables).map((variable, index) => (
                      <div key={index} className="relative w-full mb-4">
                        <div className="mb-1 text-sm whitespace-nowrap">
                          {variable}
                        </div>
                        <TextInput
                          type="text"
                          name={`variables.${variable}`}
                          value={values.variables[variable]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={showEditButton}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex flex-row mt-5">
                    {showEditButton ? (
                      <button
                        type="button"
                        className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-orange-600 border border-transparent rounded-md shadow-sm h-fit hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:col-start-2 sm:text-sm"
                        onClick={() => {
                          setShowEditButton(false);
                        }}
                      >
                        Edit
                      </button>
                    ) : (
                      <div className="flex w-full">
                        {templateData.templateName && (
                          <button
                            type="button"
                            className="inline-flex justify-center w-full px-4 py-2 mt-3 mr-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm h-fit hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                            onClick={() => {
                              setShowEditButton(true);
                              setValues(initValues);
                            }}
                          >
                            Cancel
                          </button>
                        )}
                        <button
                          type="button"
                          className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-orange-600 border border-transparent rounded-md shadow-sm h-fit hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:col-start-2 sm:text-sm"
                          disabled={isSubmitting || savingTemplate}
                          onClick={submitForm}
                        >
                          Save{" "}
                          {savingTemplate && (
                            <span className="ml-3">Loading...</span>
                          )}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col mt-5">
                    {templateData.id ? (
                      <div className="flex items-center mb-3">
                        <div className="relative w-full">
                          <TextInput
                            type="email"
                            name="testEmail"
                            value={values.testEmail}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter email to test"
                            className="min-w-[250px]"
                            touched={touched.testEmail}
                            error={
                              touched.testEmail && Boolean(errors.testEmail)
                            }
                            errorMessage={errors.testEmail}
                          />
                        </div>
                        <button
                          type="button"
                          disabled={!values.testEmail}
                          className="inline-flex justify-center w-full px-4 py-2 ml-3 text-base font-medium text-white bg-orange-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 h-fit hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:col-start-2 sm:text-sm"
                          onClick={() => {
                            onTestTemplate(values);
                          }}
                        >
                          Test
                        </button>
                      </div>
                    ) : null}
                    <button
                      type="button"
                      className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:col-start-1 sm:text-sm"
                      onClick={() => setTemplatePopup(false)}
                      ref={cancelButtonRef}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </form>
      </Dialog>
    </Transition.Root>
  );
};
