/* eslint-disable max-lines */
/** @format */
import { useMutation, useQuery } from "@apollo/client";
import {
  ENUM_ADDRESS_TYPE,
  ENUM_CARD_SHARING,
  ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING,
} from "@prisma/client";
import {
  appAccountSettingsAddressSchema,
  appAccountSettingsChangeCardNameSchema,
  genericPasswordSchema,
} from "@roadflex/constants";
import {
  CREATE_AUTHORIZATION_BEARER,
  CREATE_OR_UPDATE_ACCOUNT_CONTROL_SETTINGS,
  DELETE_AUTHORIZATION_BEARER,
  READ_ACCOUNT_CONTROL_SETTINGS,
  READ_ACCOUNT_DISPLAY_SETTING,
  READ_AUTHORIZATION_BEARER,
  READ_CURRENT_USER_AUTH,
  READ_USER_ACTIVITY_LOGS,
  READ_USER_CUSTOMIZED_SETTING,
  READ_USER_DETAILS,
  SEND_VERIFICATION_EMAIL_OR_SMS,
  UPDATE_ACCOUNT_DISPLAY_SETTING,
  UPDATE_BUSINESS_ADRESS,
  UPDATE_BUSINESS_INFORMATION,
  UPDATE_CUSTOMER_TYPE_UPGRADE,
  UPDATE_EMAIL_OR_PHONE_WITH_VERIFICATION_CODE,
  UPDATE_FLEET_INFORMATION,
  UPDATE_USER_PASSWORD,
} from "@roadflex/graphql";
import {
  AccountSettingControlType,
  AccountSettingDisplayType,
  AddressUpdateValues,
  CustomerTypeValue,
  PasswordFormValues,
  SendVerificationEmailOrSmsType,
  UpdateBusinessInformationValues,
  UpdateEmailOrSmsType,
  UpdateFleetInfoValues,
} from "@roadflex/types";
import { useFormik } from "formik";
import * as Yup from "yup";

type useSendVerificationEmailOrSmsProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
  initialValues: SendVerificationEmailOrSmsType;
  validationSchema: Yup.AnyObjectSchema;
};

type useUpdateEmailOrPhoneNumberProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
  initialValues: UpdateEmailOrSmsType;
};
type useUpdateAccountDisplaySettingProps = {
  initialValues: AccountSettingDisplayType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};
type useUpdateAccountControlSettingProps = {
  initialValues: AccountSettingControlType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};
type useUpdatePasswordProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
  initialValues: PasswordFormValues;
};
type useUpdateToPremiumProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
  initialValues: CustomerTypeValue;
};
type useUpdateAddressAccountSettingsProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
  initialValues: AddressUpdateValues;
};
type useUpdateBusinessAccountSettingsProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
  initialValues: UpdateBusinessInformationValues;
};

type useUpdateFleetInformationAccountSettingsProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
  initialValues: UpdateFleetInfoValues;
};

type useCreateAuthorizationBearerProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type useDeleteAuthorizationBearerProps = {
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

export function useReadAccountDisplaySetting() {
  const { data, refetch, ...rest } = useQuery(READ_ACCOUNT_DISPLAY_SETTING, {});
  const {
    readAccountDisplaySetting,
  }: { readAccountDisplaySetting: AccountSettingDisplayType } = data || {};
  return { readAccountDisplaySetting, refetch, ...rest };
}

export const useUpdateBusinessInformation = ({
  onSubmit,
  initialValues,
}: useUpdateBusinessAccountSettingsProps) => {
  const [updateBusinessInformationFn] = useMutation<{
    updateBusinessInformation: {
      code: string;
      message: string;
    };
  }>(UPDATE_BUSINESS_INFORMATION, {
    refetchQueries: [READ_USER_DETAILS],
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: appAccountSettingsChangeCardNameSchema,
    onSubmit: async (value) => {
      try {
        const res = await updateBusinessInformationFn({
          variables: { data: value },
        });
        if (res?.data?.updateBusinessInformation) {
          onSubmit(res?.data?.updateBusinessInformation, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};

export const useUpdateFleetInformation = ({
  onSubmit,
  initialValues,
}: useUpdateFleetInformationAccountSettingsProps) => {
  const [updateFleetInformationFn] = useMutation<{
    updateFleetInformation: {
      code: string;
      message: string;
    };
  }>(UPDATE_FLEET_INFORMATION, {
    refetchQueries: [READ_USER_DETAILS],
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (value) => {
      try {
        const res = await updateFleetInformationFn({
          variables: { data: value },
        });
        if (res?.data?.updateFleetInformation) {
          onSubmit(res?.data?.updateFleetInformation, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};

export const useUpdateAddress = ({
  onSubmit,
  initialValues,
}: useUpdateAddressAccountSettingsProps) => {
  const [updateAddressFn] = useMutation<{
    updateBusinessAddress: {
      code: string;
      message: string;
    };
  }>(UPDATE_BUSINESS_ADRESS, {
    refetchQueries: [READ_USER_DETAILS],
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: appAccountSettingsAddressSchema,
    onSubmit: async (value) => {
      const list = [
        {
          ...value.businessAddress,
          type: ENUM_ADDRESS_TYPE.PERMANENT,
        },
      ];

      const data = {
        businessId: value.businessId,
        list: list,
      };
      try {
        const res = await updateAddressFn({
          variables: {
            data,
          },
        });
        if (res?.data?.updateBusinessAddress) {
          onSubmit(res?.data?.updateBusinessAddress, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};

export const useUpdatePassword = ({
  onSubmit,
  initialValues,
}: useUpdatePasswordProps) => {
  const [updateUserPasswordFn] = useMutation<{
    updateUserPassword: {
      code: string;
      message: string;
    };
  }>(UPDATE_USER_PASSWORD);

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    validationSchema: genericPasswordSchema,
    onSubmit: async (value) => {
      try {
        const res = await updateUserPasswordFn({
          variables: { data: value },
        });
        if (res?.data?.updateUserPassword) {
          onSubmit(res?.data?.updateUserPassword, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });
  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};

export const useSendVerificationEmailOrSms = ({
  onSubmit,
  initialValues,
  validationSchema,
}: useSendVerificationEmailOrSmsProps) => {
  const [sendVerificationEmailOrSmsFn] = useMutation<{
    sendVerificationEmailOrSms: {
      code: string;
      message: string;
    };
  }>(SEND_VERIFICATION_EMAIL_OR_SMS, {});

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (value) => {
      const data = {
        ...value,
        currentPhoneNumber: value.currentPhoneNumber
          ?.trim()
          ?.match(/\d+/g)
          ?.join(""),
        newPhoneNumber: value.newPhoneNumber?.trim()?.match(/\d+/g)?.join(""),
      };
      try {
        const res = await sendVerificationEmailOrSmsFn({
          variables: { data },
        });
        if (res?.data?.sendVerificationEmailOrSms) {
          onSubmit(res?.data?.sendVerificationEmailOrSms, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};

export const useUpdateEmailOrPhoneNumber = ({
  onSubmit,
  initialValues,
}: useUpdateEmailOrPhoneNumberProps) => {
  const [updateEmailOrPhoneWithVerificationCodeFn] = useMutation<{
    updateEmailOrPhoneWithVerificationCode: {
      code: string;
      message: string;
    };
  }>(UPDATE_EMAIL_OR_PHONE_WITH_VERIFICATION_CODE, {
    refetchQueries: [READ_USER_DETAILS],
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      const data = {
        ...value,
        phoneNumber: value.phoneNumber?.trim()?.match(/\d+/g)?.join(""),
      };
      try {
        const res = await updateEmailOrPhoneWithVerificationCodeFn({
          variables: { data },
        });
        if (res?.data?.updateEmailOrPhoneWithVerificationCode) {
          onSubmit(res?.data?.updateEmailOrPhoneWithVerificationCode, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};

export const useReadCardOrderingFeesSettings = (email: string) => {
  const {
    data: readUserCustomizedSettings,
    refetch: readUserCustomizedSettingsRefetch,
    loading: readUserCustomizedSettingsLoading,
  } = useQuery(READ_USER_CUSTOMIZED_SETTING, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    variables: { data: { email: email } },
  });
  return {
    readUserCustomizedSettings,
    readUserCustomizedSettingsRefetch,
    readUserCustomizedSettingsLoading,
  };
};

export const useUpdateToPremium = ({
  onSubmit,
  initialValues,
}: useUpdateToPremiumProps) => {
  const [updateToPremiumFn] = useMutation<{
    updateCustomerTypeUpgrade: {
      code: string;
      message: string;
    };
  }>(UPDATE_CUSTOMER_TYPE_UPGRADE, {
    refetchQueries: [READ_CURRENT_USER_AUTH],
  });

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await updateToPremiumFn({
          variables: { data: value },
        });
        if (res?.data?.updateCustomerTypeUpgrade) {
          onSubmit(res?.data?.updateCustomerTypeUpgrade, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });
  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};

export const useReadUserActivityLogs = () => {
  const {
    data: userActivityLogsData,
    refetch: userActivityLogsRefetch,
    loading: userActivityLogsLoading,
    ...rest
  } = useQuery(READ_USER_ACTIVITY_LOGS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  return {
    userActivityLogsData,
    userActivityLogsRefetch,
    userActivityLogsLoading,
    ...rest,
  };
};

export const useUpdateAccountDisplaySetting = ({
  onSubmit,
  initialValues,
}: useUpdateAccountDisplaySettingProps) => {
  const [updateAccountDisplaySettingFn] = useMutation<{
    updateAccountDisplaySetting: {
      code: string;
      message: string;
    };
  }>(UPDATE_ACCOUNT_DISPLAY_SETTING, {
    refetchQueries: [READ_ACCOUNT_DISPLAY_SETTING],
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      const data = {
        ...value,
      };
      try {
        const res = await updateAccountDisplaySettingFn({
          variables: { data },
        });
        if (res?.data?.updateAccountDisplaySetting) {
          onSubmit(res?.data?.updateAccountDisplaySetting, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};

export const useReadAuthorizationBearer = () => {
  const { data, refetch, loading, ...rest } = useQuery<{
    readAuthorizationBearer: {
      code: string;
      message: string;
      authorizationBearer: string;
    };
  }>(READ_AUTHORIZATION_BEARER, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  return {
    data,
    refetch,
    loading,
    ...rest,
  };
};

export const useCreateAuthorizationBearer = ({
  onSubmit,
}: useCreateAuthorizationBearerProps) => {
  const [createAuthorizationBearerFn] = useMutation<{
    createAuthorizationBearer: {
      code: string;
      message: string;
    };
  }>(CREATE_AUTHORIZATION_BEARER, {
    refetchQueries: [READ_AUTHORIZATION_BEARER],
  });

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues: {},
    onSubmit: async () => {
      try {
        const res = await createAuthorizationBearerFn();
        if (res?.data?.createAuthorizationBearer) {
          onSubmit(res?.data?.createAuthorizationBearer, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });
  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};

export const useDeleteAuthorizationBearer = ({
  onSubmit,
}: useDeleteAuthorizationBearerProps) => {
  const [deleteAuthorizationBearerFn] = useMutation<{
    deleteAuthorizationBearer: {
      code: string;
      message: string;
    };
  }>(DELETE_AUTHORIZATION_BEARER, {
    refetchQueries: [READ_AUTHORIZATION_BEARER],
  });

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest
  } = useFormik({
    initialValues: {},
    onSubmit: async () => {
      try {
        const res = await deleteAuthorizationBearerFn({});
        if (res?.data?.deleteAuthorizationBearer) {
          onSubmit(res?.data?.deleteAuthorizationBearer, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });
  return {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    ...rest,
  };
};

export const useReadAccountControlSetting = () => {
  const {
    data: accountControlSettingData,
    refetch: accountControlSettingRefetch,
    loading: accountControlSettingLoading,
    ...rest
  } = useQuery<{
    readAccountControlSettings: {
      code: string;
      message: string;
      dynamicPurchaseControlsLinkedTo: ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING;
      cardSharingControls: ENUM_CARD_SHARING;
    };
  }>(READ_ACCOUNT_CONTROL_SETTINGS, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  return {
    accountControlSettingData,
    accountControlSettingRefetch,
    accountControlSettingLoading,
    ...rest,
  };
};

export const useUpdateAccountControlSetting = ({
  onSubmit,
  initialValues,
}: useUpdateAccountControlSettingProps) => {
  const [createOrUpdateAccountControlSettingFn] = useMutation<{
    createOrUpdateAccountControlSettings: {
      code: string;
      message: string;
    };
  }>(CREATE_OR_UPDATE_ACCOUNT_CONTROL_SETTINGS, {
    refetchQueries: [READ_ACCOUNT_CONTROL_SETTINGS],
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      const data = {
        ...value,
      };
      try {
        const res = await createOrUpdateAccountControlSettingFn({
          variables: { data },
        });
        if (res?.data?.createOrUpdateAccountControlSettings) {
          onSubmit(res?.data?.createOrUpdateAccountControlSettings, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};
